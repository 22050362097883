import React, { PropsWithChildren, useCallback, useState } from "react";
import { Field, Form } from "react-final-form";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import {
  createValidation,
  required,
  cp,
  max,
  min,
  email,
  number,
} from "../../../utils/field-validation";
import { ButtonVariant, useToOption } from "../../../utils/helper";
import Button from "../../../components/common/Button";
import Modal, { useModal } from "../../../components/common/Modal";
import ModalFooterButtonLayout from "../../../components/ModalFooterButtonLayout";
import {
  AutoCompleteField,
  InputField,
  SuggestionInputField,
  UploadImageField,
} from "../../../components/fields";
import { useCurrUserEdit } from "../../../services/user/user-query";
import appModal from "../../../utils/app-modal";
import { User } from "../../../services/user/user-types";
import {
  useDistrictList,
  useProvinceList,
} from "../../../services/master/master-query";
import { OnChange } from "react-final-form-listeners";
import { UserPositionList } from "../../../services/master/master-types";

export type UserFormValue = Omit<User, "id" | "createdAt" | "updatedAt">;

export type UserProfileEditFormProps = {
  initialValues: Partial<UserFormValue>;
  id: string;
  title: string;
  variant: ButtonVariant;
};

const Grid = styled.div`
  display: grid;
  margin: auto;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 10px;
  .MuiFormControl-marginDense {
    width: 100%;
  }
`;

const validate = createValidation<UserFormValue>(() => ({
  citizenNumber: cp(
    required("โปรดระบุรหัสประจำตัวประชาชน"),
    number("รหัสประจำตัวประชาชนไม่ถูกต้อง"),
    max(13, "รหัสประจำตัวประชาชนไม่ถูกต้อง"),
    min(13, "รหัสประจำตัวประชาชนไม่ถูกต้อง")
  ),
  firstname: required("โปรดระบุชื่อ"),
  lastname: required("โปรดระบุนามสกุล"),
  phoneNumber: cp(
    required("โปรดระบุหมายเลขโทรศัพท์"),
    number("เลขโทรศัพท์ไม่ถูกต้อง"),
    min(9, "เลขโทรศัพท์ไม่ถูกต้อง"),
    max(10, "เลขโทรศัพท์ไม่ถูกต้อง")
  ),
  email: cp(required("โปรดระบุอีเมล์"), email("อีเมล์ไม่ถูกต้อง")),
  positionName: required("โปรดระบุตำแหน่ง"),
  workGroupName: required("โปรดระบุกลุ่มงาน"),
  workPlaceName: required("โปรดระบุชื่อสถานที่"),
  workPlaceDistrict: required("โปรดระบุอำเภอ"),
  workPlaceProvince: required("โปรดระบุจังหวัด"),
}));

const UserPorfileEditButton = (
  props: PropsWithChildren<UserProfileEditFormProps>
) => {
  const { title, initialValues, id, children, variant } = props;
  const { t } = useTranslation();
  const [imgLoading, setImageLoading] = useState(false);
  const { mutateAsync: editUser } = useCurrUserEdit();
  const { props: modalProps, act: modalAct } = useModal();
  const onButtonClick = useCallback(() => {
    modalAct.show();
  }, [modalAct]);
  const onSubmit = useCallback(
    async (values: UserFormValue) => {
      try {
        if (id) {
          await editUser({ values });
          appModal.info(t("general.message.success"));
        }
        modalAct.hide();
      } catch {}
    },
    [id, t, modalAct, editUser]
  );

  const [province, setProvince] = useState<string | undefined>(
    initialValues?.workPlaceProvince
  );
  const { data: provinces = [] } = useProvinceList();
  const { data: districts = [] } = useDistrictList(province);
  const proviceOption = useToOption(provinces);
  const districtOption = useToOption(districts);

  return (
    <>
      <Button onClick={onButtonClick} variant={variant}>
        {children}
      </Button>
      <Modal size="lg" title={title} {...modalProps}>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          subscription={{ submitting: true, values: true }}
          validate={validate}
          render={({ handleSubmit, submitting, form, values }) => {
            return (
              <form id="userProfileForm" onSubmit={handleSubmit}>
                <Field
                  name="avatarId"
                  label="uploadImage"
                  component={UploadImageField}
                  onLoading={setImageLoading}
                  style={{
                    width: 150,
                    height: 150,
                    margin: "0 auto 20px",
                  }}
                />
                <Grid>
                  <Field
                    component={InputField}
                    name="citizenNumber"
                    label="รหัสประจำตัวประชาชน"
                  />
                  <Field component={InputField} name="firstname" label="ชื่อ" />
                  <Field
                    component={InputField}
                    name="lastname"
                    label="นามสกุล"
                  />
                  <Field
                    component={InputField}
                    name="phoneNumber"
                    label="หมายเลขโทรศัพท์"
                  />
                  <Field component={InputField} name="email" label="อีเมล์" />
                  <Field
                    name="positionName"
                    label="ตำแหน่ง"
                    component={SuggestionInputField}
                    optionUrl="/master/user_positions"
                    optionNormalizer={(data: UserPositionList) =>
                      data.userPositions.map((name) => ({ value: name }))
                    }
                  />
                  <Field
                    component={InputField}
                    name="workGroupName"
                    label="สถานที่ทำงาน"
                  />
                  <Field
                    component={InputField}
                    name="workPlaceName"
                    label="ชื่อสถานที่"
                  />
                  <Field
                    component={AutoCompleteField}
                    name="workPlaceProvince"
                    label="จังหวัด"
                    options={proviceOption}
                  />
                  <OnChange name="workPlaceProvince">
                    {(value) => {
                      setProvince(value);
                      form.change("workPlaceDistrict", undefined);
                    }}
                  </OnChange>
                  <Field
                    component={AutoCompleteField}
                    name="workPlaceDistrict"
                    label="อำเภอ"
                    options={districtOption}
                  />
                  {/* <Field
                    name="role"
                    label="บทบาทการใช้งาน"
                    component={SelectField}
                    items={roleOption}
                  /> */}
                </Grid>

                <ModalFooterButtonLayout>
                  <Button onClick={modalAct.hide} variant="secondary">
                    {t("general.message.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    isLoading={submitting}
                    disabled={imgLoading}
                  >
                    {t("general.message.save")}
                  </Button>
                </ModalFooterButtonLayout>
              </form>
            );
          }}
        />
      </Modal>
    </>
  );
};

export default UserPorfileEditButton;
