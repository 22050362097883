import React from "react";

type AuthInputProps = {
  input: any;
  meta: any;
  [key: string]: any;
};

const getInputClasses = (meta: any) => {
  if (meta.error && meta.touched) {
    return "is-invalid";
  }
  if (!meta.error && meta.touched) {
    return "is-valid";
  }
  return "";
};

export default (props: AuthInputProps) => {
  const { input, meta, ...componentProps } = props;
  return (
    <>
      <input
        {...input}
        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
          meta
        )}`}
        {...componentProps}
      />
      {meta.error && meta.touched && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{meta.error}</div>
        </div>
      )}
    </>
  );
};
