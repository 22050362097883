/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useMemo } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Auth, ChangePassword } from "./pages/auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import * as paths from "./constants/path";
import AppModal from "./components/AppModal";
import { useCurrUser } from "./services/auth/auth-query";

export function Routes() {
  const { data, isLoading } = useCurrUser();
  const isAuthorized = useMemo(() => {
    return !!data;
  }, [data]);

  return isLoading ? null : (
    <>
      <AppModal />
      <Switch>
        {!isAuthorized ? (
          <Route>
            <Auth />
          </Route>
        ) : (
          <Redirect from={paths.auth()} to="/" />
        )}

        <Route path={paths.error()} component={ErrorsPage} />

        {!isAuthorized ? (
          <Redirect to={paths.login()} />
        ) : (
          <Switch>
            {data?.isTemporaryPassword ? (
              <Route
                path={paths.firstChangePassword()}
                component={ChangePassword}
              />
            ) : (
              <Redirect exact from={paths.firstChangePassword()} to="/" />
            )}

            {data?.isTemporaryPassword ? (
              <Redirect to={paths.firstChangePassword()} />
            ) : (
              <Switch>
                <Route
                  path={paths.changePassword()}
                  component={ChangePassword}
                />
                <Layout>
                  <BasePage />
                </Layout>
              </Switch>
            )}
          </Switch>
        )}
      </Switch>
    </>
  );
}
