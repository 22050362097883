import React, { useCallback } from "react";
import { Card } from "react-bootstrap";
import styled from "@emotion/styled";

import SampleTable, { useSampleTable } from "./SampleTable";
import * as paths from "../../../constants/path";
import { useRouter } from "../../../utils/helper";
import ExportExcelButton from "./ExportExcelButton";

const HeaderLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FilterLayout = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  > * {
    margin-left: 10px;
  }
`;

export default () => {
  const { push } = useRouter();

  // table
  const tableProps = useSampleTable("param");
  const onShowSampleClick = useCallback(
    (row) => {
      push(
        paths.sampleDetail({
          routeParam: {
            sampleId: row.id,
          },
        })
      );
    },
    [push]
  );
  // end table`

  return (
    <>
      <Card>
        <Card.Header style={{ padding: "1.25rem 2.25rem" }}>
          <HeaderLayout>
            <h4>ข้อมูลตัวอย่าง</h4>
            <FilterLayout>
              <ExportExcelButton />
            </FilterLayout>
          </HeaderLayout>
        </Card.Header>
        <Card.Body style={{ padding: "1.25rem 2.25rem" }}>
          <SampleTable {...tableProps} onViewClick={onShowSampleClick} />
        </Card.Body>
      </Card>
    </>
  );
};
