import React from "react";
import styled from "@emotion/styled";
import { Card } from "react-bootstrap";

import { useSample } from "../../../services/sample/sample-query";
import { useRouter } from "../../../utils/helper";
import SampleDeleteButton from "../shared/SampleDeleteButton";
import SampleEditButton from "../shared/SampleEditButton";
import { Redirect } from "react-router";
import * as paths from "../../../constants/path";
import { useTranslation } from "react-i18next";
import { options } from "../../../constants/options";

const DetailLayout = styled.div`
  display: grid;
  max-width: 700px;
  grid-template-columns: 150px 1fr;
  grid-gap: 10px;
`;
const Label = styled.div`
  font-weight: bold;
`;
const Value = styled.div``;
const ButtonLayout = styled.div`
  display: flex;
  > * {
    margin-right: 10px;
  }
`;

export default () => {
  const { t } = useTranslation();
  const { routeParams, goBack } = useRouter();
  const { data: sample, isLoading } = useSample(routeParams.sampleId);

  if (isLoading) return <div>Loading...</div>;
  if (!isLoading && !sample) return <Redirect to={paths.sample()} />;
  return (
    <Card>
      <Card.Header>
        <h4>ข้อมูลตัวอย่าง</h4>
      </Card.Header>
      <Card.Body>
        <DetailLayout>
          <Label>ชื่อตัวอย่าง</Label>
          <Value>{sample?.sampleName || "-"}</Value>
          <Label>ร้านค้า</Label>
          <Value>{sample?.shop.shopName || "-"}</Value>
          <Label>แหล่งที่มา</Label>
          <Value>{sample?.source || "-"}</Value>
          <Label>รายการที่ทดสอบ</Label>
          {options.sampleType.other === sample?.sampleType ? (
            <Value>
              {t(`options.sampleType.${sample?.sampleType}`, "-")}
              {" : "}
              {sample?.sampleTypeOtherDesc || ""}{" "}
            </Value>
          ) : (
            <Value>{t(`options.sampleType.${sample?.sampleType}`, "-")}</Value>
          )}
          <Label>วันที่ลงข้อมูล</Label>
          <Value>
            {sample?.testDate.toDayjs().format("DD/MM/BBBB") || "-"}
          </Value>
          <Label>ผลทดสอบ</Label>
          <Value>{sample?.isPass ? "ผ่าน" : "ไม่ผ่าน"}</Value>
          <Label>ผู้ลงข้อมูล</Label>
          <Value>
            {`${sample?.tester.firstname} ${sample?.tester.lastname}` || "-"}
          </Value>
        </DetailLayout>
        <ButtonLayout style={{ marginTop: 25 }}>
          <SampleEditButton
            title="แก้ไขข้อมูลตัวอย่าง"
            initialValues={sample}
            id={sample?.id}
            variant="outline-primary"
          >
            แก้ไขข้อมูลตัวอย่าง
          </SampleEditButton>
          <SampleDeleteButton id={sample?.id} onDeleteSuccess={goBack}>
            ลบข้อมูลตัวอย่าง
          </SampleDeleteButton>
        </ButtonLayout>
      </Card.Body>
    </Card>
  );
};
