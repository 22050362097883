import React, { useCallback } from "react";
import { Card } from "react-bootstrap";
import styled from "@emotion/styled";

import UserTable, { useUserTable } from "./UserTable";
import UserEditButton from "../shared/UserEditButton";
import * as paths from "../../../constants/path";
import { useRouter } from "../../../utils/helper";

const HeaderLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FilterLayout = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  > * {
    margin-left: 10px;
  }
`;

export default () => {
  const { push } = useRouter();

  // table
  const tableProps = useUserTable("param");
  const onShowUserClick = useCallback(
    (row) => {
      push(
        paths.userDetail({
          routeParam: {
            userId: row.id,
          },
        })
      );
    },
    [push]
  );
  // end table`

  return (
    <>
      <Card>
        <Card.Header style={{ padding: "1.25rem 2.25rem" }}>
          <HeaderLayout>
            <h4>ผู้ใช้งาน</h4>
            <FilterLayout>
              <UserEditButton title="เพิ่มผู้ใช้งาน" variant="primary">
                เพิ่มผู้ใช้งาน
              </UserEditButton>
            </FilterLayout>
          </HeaderLayout>
        </Card.Header>
        <Card.Body style={{ padding: "1.25rem 2.25rem" }}>
          <UserTable {...tableProps} onViewClick={onShowUserClick} />
        </Card.Body>
      </Card>
    </>
  );
};
