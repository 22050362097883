import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import TH from "./th.json";
import EN from "./en.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      ...EN,
    },
  },
  th: {
    translation: {
      ...TH,
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "th",
  });

export default i18n;
