import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/common/Button";
import Table, {
  TablePropsType,
  useTable,
  useTableWithLocalState,
} from "../../../components/common/Table";
import { options } from "../../../constants/options";
import * as paths from "../../../constants/path";
import { useAlertList } from "../../../services/alert/alert-query";
import { useRouter } from "../../../utils/helper";

type AlertTableProps = Omit<TablePropsType, "columns">;

type ColumnParams = {
  onViewClick: (row: any) => void;
  page?: number;
  sizePerPage?: number;
};

export const useAlertTable = (
  mode?: "param" | "state",
  extendedParam?: any
) => {
  const { push } = useRouter();
  const modeFn = mode === "param" ? useTable : useTableWithLocalState;
  const { props } = modeFn({
    routePath: paths.sample,
    query: useAlertList,
    itemKey: "alerts",
    extendedParam,
  });
  const onViewClick = useCallback(
    (row: any) => {
      push(
        paths.foodAlertDetail({
          routeParam: {
            foodAlertId: row.id,
          },
        })
      );
    },
    [push]
  );

  return {
    ...props,
    onViewClick,
  };
};

const useColumns = ({ onViewClick, page, sizePerPage }: ColumnParams) => {
  const { t } = useTranslation();
  return useMemo(
    () => [
      {
        dataField: "no",
        text: "ลำดับ",
        formatter: (_text: string, _row: any, index: number) => {
          return ((page || 1) - 1) * (sizePerPage || 10) + (index + 1);
        },
      },
      {
        dataField: "alertNo",
        text: "เลขที่แจ้ง",
      },
      {
        dataField: "issueDate",
        text: "วันที่แจ้ง",
        formatter: (text: string) => text.toDayjs().format("DD/MM/BBBB"),
      },
      {
        dataField: "shop.shopName",
        text: "ร้านค้า",
      },
      {
        dataField: "shop.district",
        text: "อำเภอ",
      },
      {
        dataField: "shop.province",
        text: "จังหวัด",
      },
      {
        dataField: "foodName",
        text: "ชื่ออาหาร",
      },
      {
        dataField: "foodDetail",
        text: "รายละเอียดอาหาร",
      },
      {
        dataField: "status",
        text: "สถานะ",
        formatter: (text: keyof typeof options.alertStatus) =>
          t(`options.alertStatus.${text}`, "-"),
      },
      {
        dataField: "acts",
        text: "",
        formatter: (_text: string, row: any) => (
          <Button variant="link" onClick={onViewClick.bind(null, row)}>
            ดูรายละเอียด
          </Button>
        ),
        classes: "text-right pr-0",
        headerClasses: "text-right pr-3",
        style: {
          minWidth: "110px",
        },
      },
    ],
    [onViewClick, page, sizePerPage, t]
  );
};

export default (
  props: AlertTableProps & { onViewClick: (row: any) => void }
) => {
  const { onViewClick, ...restProps } = props;
  const columns = useColumns({
    onViewClick,
    page: restProps.paginationOptions.page,
    sizePerPage: restProps.paginationOptions.sizePerPage,
  });
  return <Table {...restProps} columns={columns} />;
};
