import React from "react";
import Menu from "./Menu";
import * as paths from "../../../constants/path";
import { useCurrUser } from "../../../services/auth/auth-query";
import { UserRole } from "../../../services/user/user-types";

export default () => {
  const { data: user } = useCurrUser();
  return (
    <>
      <Menu
        path={paths.dashboard()}
        title="ภาพรวม"
        iconUrl="/media/svg/icons/Design/Layers.svg"
      />
      <Menu
        path={paths.shop()}
        title="ข้อมูลร้านค้า"
        iconUrl="/media/svg/icons/Shopping/Bag2.svg"
      />
      <Menu
        path={paths.sample()}
        title="ข้อมูลตัวอย่าง"
        iconUrl="/media/svg/icons/Food/Carrot.svg"
      />
      <Menu
        path={paths.foodAlert()}
        title="แจ้งเตือนภัย"
        iconUrl="/media/svg/icons/Communication/Urgent-mail.svg"
      />
      {user?.role === UserRole.Admin && (
        <Menu
          path={paths.user()}
          title="จัดการผู้ใช้งาน"
          iconUrl="/media/svg/icons/Communication/Add-user.svg"
        />
      )}

      {/* <Menu
        path="/dashboard"
        title="Dashboard"
        iconUrl="/media/svg/icons/Design/Layers.svg"
      />
      <SectionTitle title="Component" />
      <SubMenu
        title="Material UI"
        path="/google-material"
        iconUrl="/media/svg/icons/Design/Cap-2.svg"
      >
        <SubMenu hasDot path="/google-material/inputs" title="Inputs">
          <Menu
            hasDot
            path="/google-material/inputs/autocomplete"
            title="Autocomplete"
          />
          <Menu hasDot path="/google-material/inputs/buttons" title="Buttons" />
          <Menu
            hasDot
            path="/google-material/inputs/checkboxes"
            title="Checkboxes"
          />
        </SubMenu>

        <SubMenu hasDot path="/google-material/navigation" title="Navigation">
          <Menu
            hasDot
            path="/google-material/navigation/bottom-navigation"
            title="Bottom Navigation"
          />
          <Menu
            hasDot
            path="/google-material/navigation/breadcrumbs"
            title="Breadcrumbs"
          />
          <Menu
            hasDot
            path="/google-material/navigation/drawer"
            title="Drawers"
          />
        </SubMenu>
      </SubMenu> */}
    </>
  );
};
