import { useQuery, useMutation, useQueryClient } from "react-query";
import { ShopFormValue } from "../../pages/shop/shared/ShopEditButton";

import { api } from "../../utils/api";
import { Paging } from "../shared-types";
import { Shop, ShopList } from "./shop-types";

const SHOP = "shops";

export const useShopList = (
  params: Partial<Paging> & { [key: string]: any }
) => {
  return useQuery([SHOP, params], async () => {
    const response = await api.foodSafety.get<ShopList>(SHOP, params);
    return response.data;
  });
};

export const useShopSearch = () => {
  return useMutation(async (params) => {
    const response = await api.foodSafety.get<ShopList>(SHOP, params);
    return response.data;
  });
};

export const useShop = (id: string) => {
  return useQuery([SHOP, id], async () => {
    const response = await api.foodSafety.get<{ shop: Shop }>(`${SHOP}/${id}`);
    return response.data.shop;
  });
};

export const useShopAdd = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, ShopFormValue>(
    (params) => {
      return api.foodSafety.post(SHOP, params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SHOP]);
      },
    }
  );
};

export const useShopDelete = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, string>(
    (shopId: string) => {
      return api.foodSafety.delete(`${SHOP}/${shopId}`);
    },
    {
      onSuccess: (data, shopId) => {
        queryClient.removeQueries([SHOP, shopId]);
      },
    }
  );
};

export const useShopEdit = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    unknown,
    { shopId: string; values: ShopFormValue }
  >(
    ({ shopId, values }) => {
      return api.foodSafety.patch(`${SHOP}/${shopId}`, values);
    },
    {
      onSuccess: (data, { shopId }) => {
        queryClient.invalidateQueries([SHOP, shopId]);
      },
    }
  );
};
