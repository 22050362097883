import React, { useCallback, useMemo, useState } from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useDebounce } from "../../utils/helper";
import { api } from "../../utils/api";

type OptionType = { label: string; value: string };
type AutoCompleteProps = {
  value: any;
  onChange: any;
  options: OptionType[];
  label?: string;
  onTextChange?: (text: string) => void;
};

export const useAsyncOption = <TData extends any>(
  url: string,
  normalize: (data: TData) => { label: string; value: string }[],
  defaultOption: { label: string; value: string }[]
) => {
  const [options, setOption] = useState<{ label: string; value: string }[]>(
    defaultOption
  );
  const search = useDebounce(async (value: string) => {
    if (value) {
      const response = await api.foodSafety.get<TData>(url, {
        q: value,
        limit: 10,
      });
      const options = normalize(response.data);
      setOption(options);
    } else {
      setOption(defaultOption);
    }
  }, 200);
  const onTextChange = useCallback(
    (value: string) => {
      search(value);
    },
    [search]
  );

  return {
    options,
    onTextChange,
  };
};

export default (props: AutoCompleteProps) => {
  const { options = [], onChange, value, label, onTextChange } = props;

  const customOnChange = useCallback(
    (_e, newValue: OptionType) => {
      onChange(newValue?.value);
      onTextChange && onTextChange(newValue?.label);
    },
    [onChange, onTextChange]
  );
  const customOnTextChange = useCallback(
    (e) => {
      onTextChange && onTextChange(e?.target?.value);
    },
    [onTextChange]
  );
  const tmpValue = useMemo(() => {
    return options.find((opt) => opt.value === value) || null;
    // eslint-disable-next-line
  }, [value]);

  return (
    <Autocomplete
      options={options}
      value={tmpValue}
      getOptionLabel={(option) => option.label}
      onInputChange={customOnTextChange}
      autoSelect
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          margin="dense"
        />
      )}
      // @ts-ignore
      onChange={customOnChange}
    />
  );
};
