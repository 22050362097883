import { useMutation, useQuery, useQueryClient } from "react-query";
import { SampleFormValue } from "../../pages/sample/shared/SampleEditButton";
import { api } from "../../utils/api";
import { Paging } from "../shared-types";
import { DownloadExcelParam, Sample, SampleList } from "./sample-type";
import downloadjs from "downloadjs";
import dayjs from "dayjs";
import { ExportExcelFormValue } from "../../pages/sample/SampleList/ExportExcelButton";

export const SAMPLE_URL = "samples";

export const useSamples = (
  params: Partial<Paging> & { [key: string]: any }
) => {
  return useQuery([SAMPLE_URL, params], async () => {
    const response = await api.foodSafety.get<SampleList>(SAMPLE_URL, params);
    return response.data;
  });
};

export const useSample = (id: string) => {
  return useQuery([SAMPLE_URL, id], async () => {
    const response = await api.foodSafety.get<{ sample: Sample }>(
      `${SAMPLE_URL}/${id}`
    );
    return response.data.sample;
  });
};

export const useSampleEdit = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    unknown,
    { sampleId: string; values: SampleFormValue }
  >(
    ({ sampleId, values }) => {
      return api.foodSafety.patch(`${SAMPLE_URL}/${sampleId}`, values);
    },
    {
      onSuccess: (data, { sampleId }) => {
        queryClient.invalidateQueries([SAMPLE_URL, sampleId]);
      },
    }
  );
};

export const useSampleAdd = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, SampleFormValue>(
    (params) => {
      return api.foodSafety.post(SAMPLE_URL, params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SAMPLE_URL]);
      },
    }
  );
};

export const useSampleDelete = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, string>(
    (sampleId: string) => {
      return api.foodSafety.delete(`${SAMPLE_URL}/${sampleId}`);
    },
    {
      onSuccess: (data, sampleId) => {
        queryClient.removeQueries([SAMPLE_URL, sampleId]);
      },
    }
  );
};

export const useDownloadSampleFile = () => {
  return useMutation(async (params: DownloadExcelParam) => {
    const res = await api.foodSafety.getFile(`${SAMPLE_URL}/export`, params);
    downloadjs(res.data, `food_safety_${dayjs().format("DD-MM-BB")}.xlsx`);
    return res.data;
  });
};
