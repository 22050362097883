import { makeField } from "./tools";
import Select from "../common/Select";
import AddressInput from "../common/AddressInput";
import Input from "../common/Input";
import Autocomplete from "../common/Autocomplete";
import DatePicker from "../common/DatePicker";
import SuggestionInput from "../common/SuggestionInput";
import UploadFile from "../common/UploadImage";

export const InputField = makeField(Input);
export const SelectField = makeField(Select);
export const AddressInputField = makeField(AddressInput);
export const AutoCompleteField = makeField(Autocomplete);
export const DatePickerField = makeField(DatePicker);
export const SuggestionInputField = makeField(SuggestionInput);
export const UploadImageField = makeField(UploadFile);
