import React, { CSSProperties, useMemo } from "react";
import { Bar } from "react-chartjs-2";

import styled from "@emotion/styled";
import { useDistrictTestResult } from "../../services/dashboard/dashboard-query";
import { DistrictTestResult } from "../../services/dashboard/dashboard-type";

const passColor = "#1bc5bd";
const notPassColor = "#f64e60";

const RootLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const BarLayout = styled.div`
  width: 700px;
`;

const createChartData = (data: DistrictTestResult) => {
  const labels = data.map((v) => v.district);
  const pass = data.map((v) => v.testResult.passedCount);
  const notPass = data.map((v) => v.testResult.notPassCount);
  return {
    labels,
    datasets: [
      {
        label: "ผ่าน",
        data: pass,
        backgroundColor: passColor,
      },
      {
        label: "ไม่ผ่าน",
        data: notPass,
        backgroundColor: notPassColor,
      },
    ],
  };
};

type BarChartProps = {
  style?: CSSProperties;
  className?: string;
};

const BarChart = (props: BarChartProps) => {
  const { style, className } = props;
  const { data = [] } = useDistrictTestResult();
  const chartData = useMemo(() => {
    return createChartData(data);
  }, [data]);
  return (
    <RootLayout style={style} className={className}>
      <BarLayout>
        <Bar type="" data={chartData} />
      </BarLayout>
    </RootLayout>
  );
};

export default BarChart;
