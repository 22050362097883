import React, { CSSProperties, useMemo } from "react";
import { Pie } from "react-chartjs-2";
import { Field, Form } from "react-final-form";

import {
  AutoCompleteField,
  SelectField,
  DatePickerField,
} from "../../components/fields";
import { useOptions } from "../../constants/options";
import { useDistrictList } from "../../services/master/master-query";
import { useToOption } from "../../utils/helper";
import styled from "@emotion/styled";
import { useTestResult } from "../../services/dashboard/dashboard-query";
import { OnChange } from "react-final-form-listeners";
import { useState } from "react";
import { TestResult } from "../../services/dashboard/dashboard-type";
import { sum } from "lodash";

const passColor = "#1bc5bd";
const notPassColor = "#f64e60";

const RootLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* > * {
    flex: 1;
  } */
`;
const FormLayout = styled.form`
  justify-content: "space-around";
  width: 100%;
  max-width: 300px;
  .MuiFormControl-marginDense {
    width: 100%;
  }
`;
const PieLayout = styled.div`
  width: 300px;
`;

const createChartData = (data: TestResult) => {
  return {
    labels: ["ผ่าน", "ไม่ผ่าน"],
    datasets: [
      {
        data: [data.passedCount, data.notPassCount],
        backgroundColor: [passColor, notPassColor],
        borderWidth: 0,
      },
    ],
  };
};

const chartOption = {
  plugins: {
    tooltip: {
      callbacks: {
        label: (tooltipItem: any) => {
          const total = sum(tooltipItem.dataset.data);
          const value = Number(tooltipItem.formattedValue);
          const percent = Math.round((value / total) * 100);
          return `${tooltipItem.label}: ${value} (${percent}%)`;
        },
      },
    },
  },
};

type PieChartProps = {
  style?: CSSProperties;
  className?: string;
};
const PieChart = (props: PieChartProps) => {
  const { style, className } = props;
  const { data: districts = [] } = useDistrictList("นครปฐม");
  const districtOption = useToOption(districts);
  const shopTypeOptions = useOptions("shopType");
  const sampleTypeOptions = useOptions("sampleType");

  const [district, setDistrict] = useState();
  const [sampleType, setSampleType] = useState();
  const [shopType, setShopType] = useState();
  const [beginTestDate, setBeginTestDate] = useState();
  const [endTestDate, setEndTestDate] = useState();

  const { data } = useTestResult({
    "filters[district]": district,
    "filters[sample_type]": sampleType,
    "filters[shop_type]": shopType,
    "filters[begin_test_date]": beginTestDate,
    "filters[end_test_date]": endTestDate,
  });

  const chartData = useMemo(() => {
    return data ? createChartData(data) : [];
  }, [data]);

  return (
    <RootLayout style={style} className={className}>
      <PieLayout>
        <Pie type="pie" data={chartData} options={chartOption} />
      </PieLayout>
      <Form onSubmit={() => {}}>
        {({ handleSubmit }) => (
          <FormLayout onSubmit={handleSubmit}>
            <Field
              component={DatePickerField}
              name="beginTestDate"
              label="วันที่เริ่มต้น"
            />
            <OnChange name="beginTestDate">
              {(value) => {
                setBeginTestDate(value || undefined);
              }}
            </OnChange>
            <Field
              component={DatePickerField}
              name="endTestDate"
              label="วันที่สิ้นสุด"
            />
            <OnChange name="endTestDate">
              {(value) => {
                setEndTestDate(value || undefined);
              }}
            </OnChange>
            <Field
              component={AutoCompleteField}
              name="district"
              label="อำเภอ"
              options={districtOption}
            />
            <OnChange name="district">
              {(value) => {
                setDistrict(value || undefined);
              }}
            </OnChange>
            <Field
              component={SelectField}
              name="shopType"
              label="ประเภท"
              items={shopTypeOptions}
            />
            <OnChange name="shopType">
              {(value) => {
                setShopType(value || undefined);
              }}
            </OnChange>
            <Field
              component={SelectField}
              name="sampleType"
              label="รายการทดสอบ"
              items={sampleTypeOptions}
            />
            <OnChange name="sampleType">
              {(value) => {
                setSampleType(value || undefined);
              }}
            </OnChange>
          </FormLayout>
        )}
      </Form>
    </RootLayout>
  );
};

export default PieChart;
