import { useQuery, UseQueryOptions } from "react-query";
import { api } from "../../utils/api";
import {
  DistrictTestResult,
  TestResult,
  TestResultParam,
} from "./dashboard-type";

const DASHBOARD = "dashboard";
const TEST_RESULT = `${DASHBOARD}/test_result`;
const DISTRICT_TEST_RESULTS = `${DASHBOARD}/district_test_results`;

export const useTestResult = (params: TestResultParam) => {
  return useQuery([DASHBOARD, TEST_RESULT, params], async () => {
    const res = await api.foodSafety.get<{ testResult: TestResult }>(
      TEST_RESULT,
      params
    );
    return res.data.testResult;
  });
};

export const useDistrictTestResult = (
  option?: UseQueryOptions<DistrictTestResult>
) => {
  return useQuery<DistrictTestResult>(
    [DASHBOARD, DISTRICT_TEST_RESULTS],
    async () => {
      const res = await api.foodSafety.get<{
        districtTestResults: DistrictTestResult;
      }>(DISTRICT_TEST_RESULTS);
      return res.data.districtTestResults;
    },
    option
  );
};
