import { Paging } from "../shared-types";

export enum UserRole {
  DistrictOfficer = "district_officer", // เจ้าหน้าที่ระดับอำเภอ
  ProvinceOfficer = "province_officer", // เจ้าหน้าที่ระดับจังหวัด
  Admin = "admin", // ผู้ดูแลระบบ
}

export type User = {
  citizenNumber: string;
  createdAt: string;
  email: string;
  firstname: string;
  id: string;
  isTemporaryPassword: boolean;
  lastname: string;
  phoneNumber: string;
  positionName: string;
  role: UserRole;
  updatedAt: string;
  workGroupName: string;
  workPlaceDistrict: string;
  workPlaceName: string;
  workPlaceProvince: string;
  avatarId: string;
};

export type UserList = {
  users: User[];
  meta: {
    paging: Paging;
  };
};

export type UserAndTempPassword = {
  temporaryPassword: string;
} & User;
