import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";

import { useShop } from "../../../services/shop/shop-query";
import { useRouter } from "../../../utils/helper";
import ShopDeleteButton from "../shared/ShopDeleteButton";
import * as pahts from "../../../constants/path";
import { Redirect } from "react-router";
import ShopEditButton from "../shared/ShopEditButton";
import { options } from "../../../constants/options";
import SampleTable, {
  useSampleTable,
} from "../../sample/SampleList/SampleTable";
import { Tabs, Tab } from "../../../components/common/tabs";
import AlertTable, { useAlertTable } from "../../alert/AlertList/AlertTable";
import * as paths from "../../../constants/path";
import SampleEditButton from "../../sample/shared/SampleEditButton";

const DetailLayout = styled.div`
  display: grid;
  max-width: 700px;
  grid-template-columns: 120px 1fr 120px 1fr;
  grid-gap: 10px;
`;
const Label = styled.div`
  font-weight: bold;
`;
const Value = styled.div``;
const ButtonLayout = styled.div`
  display: flex;
  > * {
    margin-right: 10px;
  }
`;
const SampleLayout = styled.div`
  position: relative;
`;
const SampleButtonLayout = styled.div`
  position: absolute;
  top: -5px;
  right: 0;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  > * {
    margin-left: 10px;
  }
`;

export default () => {
  const { t } = useTranslation();
  const { query, routeParams, goBack, push } = useRouter();
  const { data: shop, isLoading } = useShop(routeParams.shopId);

  const onTabSelect = useCallback(
    (v) => {
      push(
        paths.shopDetail({
          queryParam: {
            ...query,
            tab: v,
          },
          routeParam: routeParams,
        })
      );
    },
    [push, query, routeParams]
  );

  const sampleTableProps = useSampleTable("state", {
    "filters[shop_id]": routeParams.shopId,
  });
  const alertTableProps = useAlertTable("state", {
    "filters[shop_id]": routeParams.shopId,
  });

  if (isLoading) return <div>Loading...</div>;
  if (!isLoading && !shop) return <Redirect to={pahts.shop()} />;
  return (
    <Card>
      <Card.Header>
        <h4>{shop?.shopName}</h4>
      </Card.Header>
      <Card.Body>
        <DetailLayout>
          <Label>ประเภท</Label>
          {options.shopType.other === shop?.shopType ? (
            <Value>
              {t(`options.shopType.${shop?.shopType}`, "-")}
              {" : "}
              {shop?.shopTypeOtherDesc || ""}
            </Value>
          ) : (
            <Value>{t(`options.shopType.${shop?.shopType}`, "-")}</Value>
          )}
          <Label>ชื่อสถานที่</Label>
          <Value>{shop?.shopPlaceName || "-"}</Value>
          <Label>เจ้าของ</Label>
          <Value>{shop?.ownerName || "-"}</Value>
          <Label>เบอร์โทรศัพท์</Label>
          <Value>{shop?.phoneNumber || "-"}</Value>
          <Label>ที่อยู่</Label>
          <Value>{shop?.address || "-"}</Value>
          <Label>แขวง/ตำบล</Label>
          <Value>{shop?.subDistrict || "-"}</Value>
          <Label>เขต/อำเภอ</Label>
          <Value>{shop?.district || "-"}</Value>
          <Label>จังหวัด</Label>
          <Value>{shop?.province || "-"}</Value>
          <Label>รหัสไปรษณีย์</Label>
          <Value>{shop?.postCode || "-"}</Value>
          <Label>พิกัด</Label>
          <Value>
            {(shop?.latitude &&
              shop?.longitude &&
              `(${shop?.latitude}, ${shop?.longitude})`) ||
              "-"}
          </Value>
          <Label>อีเมล์</Label>
          <Value>{shop?.email || "-"}</Value>
        </DetailLayout>
        <ButtonLayout style={{ marginTop: 25 }}>
          <ShopEditButton
            title="แก้ไขร้านค้า"
            initialValues={shop}
            id={shop?.id}
            varient="outline-primary"
          >
            แก้ไขร้านค้า
          </ShopEditButton>
          <ShopDeleteButton id={shop?.id} onDeleteSuccess={goBack}>
            ลบร้านค้า
          </ShopDeleteButton>
        </ButtonLayout>

        <SampleLayout>
          <Tabs
            id="shopTabs"
            defaultActiveKey="sample"
            activeKey={query.tab}
            onSelect={onTabSelect}
            style={{ margin: "25px 0 0px" }}
          >
            <Tab
              eventKey="sample"
              title="ตัวอย่างทดสอบ"
              style={{ border: "1px solid #E4E6EF", borderTop: 0 }}
            >
              <SampleButtonLayout>
                {shop && (
                  <SampleEditButton
                    initialValues={{ shopId: shop.id }}
                    title="เพิ่มข้อมูลตัวอย่าง"
                    variant="primary"
                  >
                    เพิ่มข้อมูลตัวอย่าง
                  </SampleEditButton>
                )}
              </SampleButtonLayout>
              <div style={{ padding: "0 10px" }}>
                <SampleTable {...sampleTableProps} />
              </div>
            </Tab>
            <Tab
              eventKey="alert"
              title="แจ้งเตือนภัย"
              style={{ border: "1px solid #E4E6EF", borderTop: 0 }}
            >
              <div style={{ padding: "0 10px" }}>
                <AlertTable {...alertTableProps} />
              </div>
            </Tab>
          </Tabs>
        </SampleLayout>
      </Card.Body>
    </Card>
  );
};
