import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/common/Button";
import Table, {
  TablePropsType,
  useTable,
  useTableWithLocalState,
} from "../../../components/common/Table";
import * as paths from "../../../constants/path";
import { useSamples } from "../../../services/sample/sample-query";
import { useRouter } from "../../../utils/helper";

type SampleTableProps = Omit<TablePropsType, "columns">;

type ColumnParams = {
  onViewClick: (row: any) => void;
  page?: number;
  sizePerPage?: number;
};

export const useSampleTable = (
  mode?: "param" | "state",
  extendedParam?: any
) => {
  const { push } = useRouter();
  const modeFn = mode === "param" ? useTable : useTableWithLocalState;
  const { props } = modeFn({
    routePath: paths.sample,
    query: useSamples,
    itemKey: "samples",
    extendedParam,
  });
  const onViewClick = useCallback(
    (row: any) => {
      push(
        paths.sampleDetail({
          routeParam: {
            sampleId: row.id,
          },
        })
      );
    },
    [push]
  );

  return {
    ...props,
    onViewClick,
  };
};

const useColumns = ({ onViewClick, page, sizePerPage }: ColumnParams) => {
  const { t } = useTranslation();
  return useMemo(
    () => [
      {
        dataField: "no",
        text: "ลำดับ",
        formatter: (_text: string, _row: any, index: number) => {
          return ((page || 1) - 1) * (sizePerPage || 10) + (index + 1);
        },
      },
      {
        dataField: "sampleName",
        text: "ชื่อตัวอย่าง",
      },
      {
        dataField: "shop.shopName",
        text: "แหล่งที่มา",
      },
      {
        dataField: "sampleType",
        text: "รายการทดสอบ",
        formatter: (text: string) => t(`options.sampleType.${text}`),
      },
      {
        dataField: "testDate",
        text: "วันที่ลงข้อมูล",
        formatter: (text: string) => text.toDayjs().format("DD/MM/BBBB"),
      },
      {
        dataField: "isPass",
        text: "ผลทดสอบ",
        formatter: (text: boolean) => (text === true ? "ผ่าน" : "ไม่ผ่าน"),
      },
      {
        dataField: "testerId",
        text: "ผู้ลงข้อมูล",
        formatter: (_text: string, row: any) =>
          `${row.tester.firstname} ${row.tester.lastname}`,
      },
      {
        dataField: "acts",
        text: "",
        formatter: (_text: string, row: any) => (
          <Button variant="link" onClick={onViewClick.bind(null, row)}>
            ดูรายละเอียด
          </Button>
        ),
        classes: "text-right pr-0",
        headerClasses: "text-right pr-3",
        style: {
          minWidth: "110px",
        },
      },
    ],
    [onViewClick, page, sizePerPage, t]
  );
};

export default (
  props: SampleTableProps & { onViewClick: (row: any) => void }
) => {
  const { onViewClick, ...restProps } = props;
  const columns = useColumns({
    onViewClick,
    page: restProps.paginationOptions.page,
    sizePerPage: restProps.paginationOptions.sizePerPage,
  });
  return <Table {...restProps} columns={columns} />;
};
