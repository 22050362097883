import React, {
  ChangeEvent,
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FixedSizeList } from "react-window";

import { Paper, MenuItem } from "@material-ui/core";
import styled from "@emotion/styled";
import Input from "./Input";

const RootLayout = styled.div`
  position: relative;
`;
const Dropdown = styled(Paper)`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1000;
  overflow-y: auto;
  max-height: 300px;
`;

export type AddressInputProps = {
  onChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  value: string;
  suggestions: any[];
  renderLabel: (value: any) => string;
  onItemSelect?: (value: any) => void;
  valueKey: string;
  label: string;
  style: CSSProperties;
  className: string;
  disabled: boolean;
};

export default (props: AddressInputProps) => {
  const {
    value,
    onChange,
    suggestions,
    renderLabel,
    onItemSelect,
    label,
    valueKey,
    style: compStyle,
    className,
    disabled,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const onFocus = useCallback(() => {
    setIsOpen(true);
  }, []);
  const items = useMemo(() => {
    return suggestions.filter((val) => {
      return val[valueKey].toLowerCase().includes(value.toLowerCase());
    });
  }, [suggestions, value, valueKey]);
  const onItemSelected = useCallback(
    (value: any) => {
      onItemSelect && onItemSelect(value);
      setIsOpen(false);
    },
    [onItemSelect]
  );

  const wrapperRef = useRef<any>();
  const handleClickOutside = useCallback((event: any) => {
    if (wrapperRef.current && !wrapperRef.current?.contains(event.target)) {
      setIsOpen(false);
    }
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const Row = useMemo(() => {
    return ({ index, style }: any) => (
      <MenuItem
        style={style}
        key={renderLabel(items[index])}
        onClick={onItemSelected.bind(null, items[index])}
      >
        {renderLabel(items[index])}
      </MenuItem>
    );
  }, [items, onItemSelected, renderLabel]);

  return (
    <RootLayout style={compStyle} className={className}>
      <Input
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        label={label}
        disabled={disabled}
      />

      {isOpen && value.length > 0 && (
        <Dropdown ref={wrapperRef}>
          <FixedSizeList
            height={250}
            width="100%"
            itemCount={items.length}
            itemSize={40}
          >
            {Row}
          </FixedSizeList>
        </Dropdown>
      )}
    </RootLayout>
  );
};
