import { makeStyles, Theme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { CSSProperties } from "react";
import { useAttachment } from "../../services/attachment/attachment-query";

type AuthImgProps = {
  imgId?: string; //attachmentId
  style?: CSSProperties;
  className?: string;
};

const useStyle = makeStyles<Theme, { imgUrl?: string }>(() => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f4f5f6",
    borderRadius: 5,
    backgroundImage: (props) => `url(${props.imgUrl})`,
    backgroundSize: "cover",
  },
}));

const AuthImg = (props: AuthImgProps) => {
  const { imgId, className, style } = props;
  const [imgUrl, setImgUrl] = useState<string>();
  const { data: fileData } = useAttachment(imgId, {
    cacheTime: 5 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
  });

  const classes = useStyle({ imgUrl });

  useEffect(() => {
    try {
      setImgUrl(URL.createObjectURL(fileData));
    } catch {
      setImgUrl(undefined);
    }
  }, [fileData]);

  return <span className={[classes.root, className].join(" ")} style={style} />;
};

export default AuthImg;
