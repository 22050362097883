import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Field, Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { options } from "../../../constants/options";

import {
  createValidation,
  number,
  required,
  cp,
  min,
  max,
  latitude,
  longitude,
  when,
} from "../../../utils/field-validation";
import {
  ButtonVariant,
  makeRemoteSubmit,
  useToOption,
} from "../../../utils/helper";
import Button from "../../../components/common/Button";
import Modal, { useModal } from "../../../components/common/Modal";
import ModalFooterButtonLayout from "../../../components/ModalFooterButtonLayout";
import {
  InputField,
  SelectField,
  AutoCompleteField,
  SuggestionInputField,
} from "../../../components/fields";
import { useOptions } from "../../../constants/options";
import { Shop } from "../../../services/shop/shop-types";
import { useShopAdd, useShopEdit } from "../../../services/shop/shop-query";
import appModal from "../../../utils/app-modal";
import {
  useDistrictList,
  usePostCodeList,
  useProvinceList,
  useSubDistrictList,
} from "../../../services/master/master-query";
import { ShopPlaceList } from "../../../services/master/master-types";

export type ShopFormValue = Omit<Shop, "id" | "createdAt" | "updatedAt">;

export type ShopEditFormProps = {
  initialValues?: Partial<ShopFormValue>;
  id?: string;
  title: string;
  varient: ButtonVariant;
};

const Grid = styled.div`
  display: grid;
  margin: auto;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 10px;
  .MuiFormControl-marginDense {
    width: 100%;
  }
`;

const validate = createValidation<ShopFormValue>((value) => ({
  shopName: required("โปรดระบุชื่อร้านค้า"),
  shopType: required("โปรดระบุประเภทร้านค้า"),
  shopTypeOtherDesc: when(
    () => value?.shopType === options.shopType.other,
    required("โปรดระบุประเภทร้านค้า")
  ),
  phoneNumber: cp(
    required("โปรดระบุหมายเลขโทรศัพท์"),
    number("หมายเลขโทรศัพท์ไม่ถูกต้อง"),
    min(9, "หมายเลขโทรศัพท์ไม่ถูกต้อง"),
    max(10, "หมายเลขโทรศัพท์ไม่ถูกต้อง")
  ),
  address: required("โปรดระบุที่อยู่"),
  subDistrict: required("โปรดระบุเขต/ตำบล"),
  district: required("โปรดระบุแขวง/อำเภอ"),
  province: required("โปรดระบุจังหวัด"),
  postCode: cp(
    required("โปรดระบุหรัสไปรษณย์"),
    number("รหัสไปรษณีย์ไม่ถูกต้อง"),
    min(5, "รหัสไปรษณีย์ไม่ถูกต้อง"),
    max(5, "รหัสไปรษณีย์ไม่ถูกต้อง")
  ),
  latitude: latitude("Latitude รูปแบบไม่ถูกต้อง"),
  longitude: longitude("Longitude รูปแบบไม่ถูกต้อง"),
}));

type Address = {
  province: string;
  district: string;
  sub_district: string;
  post_code: string;
};

const defaultValue = {
  shopPlaceName: null,
  ownerName: null,
  email: null,
  latitude: null,
  longitude: null,
};

const ShopEditForm = (props: PropsWithChildren<ShopEditFormProps>) => {
  const { title, initialValues, id, children, varient } = props;
  const { t } = useTranslation();
  const { mutateAsync: editShop } = useShopEdit();
  const { mutateAsync: addshop } = useShopAdd();
  const { props: modalProps, act: modalAct } = useModal();
  const onButtonClick = useCallback(() => {
    modalAct.show();
  }, [modalAct]);
  const onSubmit = useCallback(
    async (values: ShopFormValue) => {
      try {
        if (id) {
          await editShop({
            shopId: id,
            values: { ...defaultValue, ...values },
          });
        } else {
          await addshop(values);
        }
        appModal.info(t("general.message.success"));
        modalAct.hide();
      } catch {}
    },
    [addshop, editShop, id, modalAct, t]
  );
  const shopTypeOptions = useOptions("shopType");

  const [province, setProvince] = useState(initialValues?.province);
  const [district, setDistrict] = useState(initialValues?.district);
  const [subDistrict, setSubDistrict] = useState(initialValues?.subDistrict);
  const { data: provinces = [] } = useProvinceList();
  const { data: districts = [] } = useDistrictList(province);
  const { data: subDistricts = [] } = useSubDistrictList(province, district);
  const { data: postCodes = [] } = usePostCodeList(
    province,
    district,
    subDistrict
  );

  const proviceOption = useToOption(provinces);
  const districtOption = useToOption(districts);
  const subDistrictOption = useToOption(subDistricts);
  const postCodeOption = useToOption(postCodes);

  const [otherFieldDisabled, setOtherFieldDisabled] = useState(false);
  useEffect(() => {
    setOtherFieldDisabled(options.sampleType.other !== initialValues?.shopType);
  }, [initialValues, modalProps.show]);

  return (
    <>
      <Button onClick={onButtonClick} variant={varient}>
        {children}
      </Button>
      <Modal size="lg" title={title} {...modalProps}>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          subscription={{ submitting: true }}
          validate={validate}
          render={({ handleSubmit, submitting, form }) => {
            return (
              <form id="shopEditForm" onSubmit={handleSubmit}>
                <Grid>
                  <Field
                    component={InputField}
                    name="shopName"
                    label="ชื่อร้านค้า"
                  />
                  <Field
                    component={InputField}
                    name="ownerName"
                    label="ชื่อเจ้าของร้านค้า"
                  />
                  <Field
                    component={SelectField}
                    name="shopType"
                    label="ประเภท"
                    items={shopTypeOptions}
                  />
                  <Field
                    component={InputField}
                    name="shopTypeOtherDesc"
                    label="ระบุประเภท"
                    disabled={otherFieldDisabled}
                  />
                  <OnChange name="shopType">
                    {(value) => {
                      setOtherFieldDisabled(options.shopType.other !== value);
                      form.change("shopTypeOtherDesc", "");
                      return null;
                    }}
                  </OnChange>
                  <Field
                    component={SuggestionInputField}
                    name="shopPlaceName"
                    label="ชื่อสถานที่"
                    optionUrl="/master/shop_places"
                    optionNormalizer={(data: ShopPlaceList) =>
                      data.shopPlaces.map((name: string) => ({ value: name }))
                    }
                  />
                  <Field
                    component={InputField}
                    name="phoneNumber"
                    label="หมายเลขโทรศัพท์"
                  />
                  <Field
                    component={InputField}
                    type="email"
                    name="email"
                    label="อีเมล์"
                  />
                  <Field
                    component={InputField}
                    name="address"
                    label="ที่อยู่"
                  />
                  <Field
                    component={AutoCompleteField}
                    name="province"
                    label="จังหวัด"
                    options={proviceOption}
                  />
                  <Field
                    component={AutoCompleteField}
                    name="district"
                    label="อำเภอ"
                    options={districtOption}
                  />
                  <Field
                    component={AutoCompleteField}
                    name="subDistrict"
                    label="ตำบล"
                    options={subDistrictOption}
                  />
                  <Field
                    component={AutoCompleteField}
                    name="postCode"
                    label="รหัสไปรษณีย์"
                    options={postCodeOption}
                  />
                  <Field
                    component={InputField}
                    name="latitude"
                    label="Latitude"
                  />
                  <Field
                    component={InputField}
                    name="longitude"
                    label="Longitude"
                  />
                </Grid>
                <OnChange name="province">
                  {(value) => {
                    setProvince(value);
                    setDistrict(undefined);
                    form.change("district", undefined);
                  }}
                </OnChange>
                <OnChange name="district">
                  {(value) => {
                    setDistrict(value);
                    form.change("subDistrict", undefined);
                  }}
                </OnChange>
                <OnChange name="subDistrict">
                  {(value) => {
                    setSubDistrict(value);
                    form.change("postCode", undefined);
                  }}
                </OnChange>

                <ModalFooterButtonLayout>
                  <Button onClick={modalAct.hide} variant="secondary">
                    {t("general.message.cancel")}
                  </Button>
                  <Button type="submit" isLoading={submitting}>
                    {t("general.message.save")}
                  </Button>
                </ModalFooterButtonLayout>
              </form>
            );
          }}
        />
      </Modal>
    </>
  );
};

ShopEditForm.submit = makeRemoteSubmit("shopEditForm");
export default ShopEditForm;
