import React, { CSSProperties, PropsWithChildren } from "react";
import { Button, Spinner, ButtonProps } from "react-bootstrap";

type CustomButtonProps = {
  isLoading?: boolean;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  style?: CSSProperties;
} & PropsWithChildren<ButtonProps>;

export default (props: CustomButtonProps) => {
  const { children, isLoading, disabled, ...restProps } = props;
  return (
    <Button {...restProps} disabled={disabled || isLoading}>
      {isLoading && (
        <Spinner
          style={{ marginRight: 5 }}
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
      {children}
    </Button>
  );
};
