import React, { useCallback } from "react";
import Modal from "./common/Modal";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import { hideModal } from "../../redux/app-reducer";
import Button from "./common/Button";
import ModalFooterButtonLayout from "./ModalFooterButtonLayout";
import { useTranslation } from "react-i18next";

export default () => {
  const { t } = useTranslation();
  const { show, message, title, onConfirm } = useSelector(
    (state: RootState) => state.app.modal
  );
  const dispatch = useDispatch();
  const onCancel = useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);
  const customOnConfirm = useCallback(() => {
    onConfirm();
    dispatch(hideModal());
  }, [dispatch, onConfirm]);
  return (
    <Modal show={show} title={title} onCancel={onCancel}>
      {t(`errors.${message}`, message)}
      <ModalFooterButtonLayout>
        {title !== "Confirm" && <Button onClick={onCancel}>ตกลง</Button>}
        {title === "Confirm" && (
          <>
            <Button onClick={onCancel} variant="secondary">
              ยกเลิก
            </Button>
            <Button onClick={customOnConfirm}>ตกลง</Button>
          </>
        )}
      </ModalFooterButtonLayout>
    </Modal>
  );
};
