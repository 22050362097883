import React, { useCallback, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useIsFetching } from "react-query";
import { useLogin } from "../../services/auth/auth-query";
import { cp, createValidation, required } from "../../utils/field-validation";
import AuthInput from "./AuthInput";

const initialvalues = {
  // citizenNumber: "1740300013493",
  citizenNumber: "3740300013493",
  password: "12345678",
};
type LoginFormValue = {
  citizenNumber: string;
  password: string;
};

const loginValidation = createValidation<LoginFormValue>(() => ({
  citizenNumber: cp(required("ระบุรหัสประจำตัวประชาชน")),
  password: cp(required("ระบุระหัสผ่าน")),
}));

export default () => {
  const isFetching = useIsFetching();
  const { t } = useTranslation();
  const { mutateAsync } = useLogin({ onError: () => {} }); // diable default error
  const [errorMessage, setErrorMessage] = useState("");
  const onSubmit = useCallback(
    async (value) => {
      const { citizenNumber, password } = value;
      try {
        await mutateAsync({ citizenNumber, password });
      } catch (e) {
        setErrorMessage(
          t(`errors.${e?.response?.data?.code}`, "Something Went Wrong")
        );
      }
    },
    [mutateAsync, t]
  );
  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">เข้าสู่ระบบ</h3>
        <p className="text-muted font-weight-bold">
          ระบุรหัสประจำตัวประชาชน และ รหัสผ่าน
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <Form
        initialValues={
          process.env.NODE_ENV === "development" ? initialvalues : undefined
        }
        onSubmit={onSubmit}
        subscription={{ submitting: true }}
        validate={loginValidation}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              {errorMessage && (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    {errorMessage}
                  </div>
                </div>
              )}
              <div className="form-group fv-plugins-icon-container">
                <Field
                  name="citizenNumber"
                  component={AuthInput}
                  placeholder="รหัสประจำตัวประชาชน"
                />
              </div>
              <div className="form-group fv-plugins-icon-container">
                <Field
                  name="password"
                  component={AuthInput}
                  placeholder="รหัสผ่าน"
                  type="password"
                />
              </div>
              <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                {/* <Link
                  to="/auth/forgot-password"
                  className="text-dark-50 text-hover-primary my-3 mr-2"
                  id="kt_login_forgot"
                >
                  Forgot Password
                </Link> */}
                <div />
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                >
                  <span>เข้าสู่ระบบ</span>
                  {(submitting || !!isFetching) && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};
