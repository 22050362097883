import qs from "querystring";

type ParamType = { [p: string]: any } | undefined;
export type PathOption<T extends ParamType, V extends ParamType> = {
  routeParam?: T;
  queryParam?: V;
};

const generate = (url: string) => (
  option?: PathOption<ParamType, ParamType>
) => {
  const { routeParam, queryParam } = option || {};
  let newQueryParam = "";
  if (queryParam) {
    const filterObj = Object.entries(queryParam).reduce((r, [key, value]) => {
      if (value) {
        r[key] = value;
      }
      return r;
    }, {} as any);
    newQueryParam = `?${qs.stringify(filterObj as ParamType)}`;
  }

  let newUrl = url;
  if (routeParam) {
    const urls = url.split("/");
    newUrl = urls
      .map((u) => {
        if (/:.+/.test(u)) {
          return routeParam[u.slice(1)];
        }
        return u;
      })
      .join("/");
  }

  return `${newUrl}${newQueryParam}`;
};

export const auth = generate("/auth");
export const login = generate(`${auth()}/login`);
export const firstChangePassword = generate(`/first-change-password`);
export const changePassword = generate(`/change-password`);
export const error = generate("/error");
export const errorV1 = generate(`${error()}/error-v1`);
export const logout = generate("/logout");
export const dashboard = generate("/dashboard");
export const shop = generate(`/shops`);
export const shopDetail = generate(`${shop()}/:shopId`);
export const user = generate(`/users`);
export const userDetail = generate(`${user()}/:userId`);
export const sample = generate(`/samples`);
export const sampleDetail = generate(`${sample()}/:sampleId`);
export const foodAlert = generate(`/food-alerts`);
export const foodAlertDetail = generate(`${foodAlert()}/:foodAlertId`);
export const userProfile = generate(`/me`);
