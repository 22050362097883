import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/common/Button";

import Table, {
  TablePropsType,
  useTable,
  useTableWithLocalState,
} from "../../../components/common/Table";
import { User } from "../../../services/user/user-types";
import { useRouter } from "../../../utils/helper";
import * as paths from "../../../constants/path";
import { useUserList } from "../../../services/user/user-query";

export const useUserTable = (mode?: "param" | "state", extendedParam?: any) => {
  const { push } = useRouter();
  const modeFn = mode === "param" ? useTable : useTableWithLocalState;
  const { props } = modeFn({
    routePath: paths.user,
    query: useUserList,
    itemKey: "users",
    extendedParam,
  });
  const onViewClick = useCallback(
    (row: any) => {
      push(
        paths.sampleDetail({
          routeParam: {
            sampleId: row.id,
          },
        })
      );
    },
    [push]
  );

  return {
    ...props,
    onViewClick,
  };
};

const useColumns = ({ onViewClick, page, sizePerPage }: any) => {
  const { t } = useTranslation();
  return useMemo(
    () => [
      {
        dataField: "no",
        text: t("shop.table.column.no"),
        formatter: (text: string, row: any, index: number) => {
          return ((page || 1) - 1) * (sizePerPage || 10) + (index + 1);
        },
      },
      {
        dataField: "citizenNumber",
        text: "รหัสประจำตัวประชาชน",
      },
      {
        dataField: "name",
        text: "ชื่อ - นามสกุล",
        formatter: (text: string, row: User) => {
          return `${row.firstname} ${row.lastname}`;
        },
      },
      {
        dataField: "positionName",
        text: "ตำแหน่ง",
        // formatter: (text: string, row: User) => {
        //   return t(`options.userPosition.${text}`, "-");
        // },
      },
      {
        dataField: "email",
        text: "อีเมล์",
      },
      {
        dataField: "phoneNumber",
        text: "หมายเลขโทรศัพท์",
      },
      // {
      //   dataField: "workGroupName",
      //   text: "สถานที่ทำงาน",
      // },
      // {
      //   dataField: "workPlaceName",
      //   text: "ชื่อสถานที่",
      // },
      // {
      //   dataField: "workPlaceDistrict",
      //   text: "อำเภอ",
      // },
      // {
      //   dataField: "workPlaceProvince",
      //   text: "จังหวัด",
      // },
      {
        dataField: "role",
        text: "บทบาทการใช้งาน",
        formatter: (text: string, row: User) => {
          return t(`options.userRole.${text}`, "-");
        },
      },
      {
        dataField: "action",
        text: "",
        formatter: (value: any, row: any, index: number) => {
          return (
            <>
              <Button variant="link" onClick={onViewClick.bind(null, row)}>
                ดูรายละเอียด
              </Button>
            </>
          );
        },
        classes: "text-right pr-0",
        headerClasses: "text-right pr-3",
        style: {
          minWidth: "110px",
        },
      },
    ],
    [onViewClick, page, sizePerPage, t]
  );
};

type UserTableProps = {
  onViewClick: boolean | ((row: any) => void);
} & Omit<TablePropsType, "columns">;

export default (props: UserTableProps) => {
  const { onViewClick, ...restProps } = props;
  const columns = useColumns({
    onViewClick,
    page: restProps.paginationOptions.page,
    sizePerPage: restProps.paginationOptions.sizePerPage,
  });
  return <Table {...restProps} columns={columns} />;
};
