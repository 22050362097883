import { QueryClient } from "react-query";
import { handleError } from "./handle-error";

export const client = new QueryClient({
  defaultOptions: {
    queries: {
      onError: (error) => {
        handleError(error);
      },
      retry: 0,
    },
    mutations: {
      onError: (error) => {
        handleError(error);
      },
      retry: 0,
    },
  },
});
