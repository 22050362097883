import React from "react";
import styled from "@emotion/styled";
import { Card } from "react-bootstrap";
import { Redirect } from "react-router";
import { useTranslation } from "react-i18next";

import * as paths from "../../../constants/path";
import UserProfileEditButton from "../shared/UserProfileEditButton";
import { useCurrUser } from "../../../services/auth/auth-query";
import AuthImg from "../../../components/common/AuthImg";

const DetailLayout = styled.div`
  display: grid;
  max-width: 700px;
  grid-template-columns: 150px 1fr;
  grid-gap: 10px;
`;

const Label = styled.div`
  font-weight: bold;
`;
const Value = styled.div``;
const ButtonLayout = styled.div`
  display: flex;
  > * {
    margin-right: 10px;
  }
`;

export default () => {
  const { t } = useTranslation();
  const { data: user, isLoading } = useCurrUser();

  if (isLoading) return <div>Loading...</div>;
  if (!isLoading && !user) return <Redirect to={paths.errorV1()} />;
  return (
    <Card>
      <Card.Header>
        <h4>ข้อมูลส่วนตัว</h4>
      </Card.Header>
      <Card.Body>
        <AuthImg
          imgId={user?.avatarId}
          style={{ width: 150, height: 150, marginBottom: 20 }}
        />
        <DetailLayout>
          <Label>รหัสประจำตัวประชาชน</Label>
          <Value>{user?.citizenNumber || "-"}</Value>
          <Label>ชื่อ - นามสกุล</Label>
          <Value>
            {user?.firstname || "-"} {user?.lastname || ""}
          </Value>
          <Label>หมายยเลขโทรศัพท์</Label>
          <Value>{user?.phoneNumber || "-"}</Value>
          <Label>ตำแหน่ง</Label>
          <Value>{user?.positionName || "-"}</Value>
          <Label>สถานที่ทำงาน</Label>
          <Value>{user?.workGroupName || "-"}</Value>
          <Label>ชื่อสถานที่</Label>
          <Value>{user?.workPlaceName || "-"}</Value>
          <Label>อำเภอ</Label>
          <Value>{user?.workPlaceDistrict || "-"}</Value>
          <Label>จังหวัด</Label>
          <Value>{user?.workPlaceProvince || "-"}</Value>
          <Label>บทบาทการใช้งาน</Label>
          <Value>{t(`options.userRole.${user?.role}`, "-")}</Value>
        </DetailLayout>
        {user && (
          <ButtonLayout style={{ marginTop: 25 }}>
            <UserProfileEditButton
              title="แก้ไขข้อมูลส่วนตัว"
              initialValues={user}
              id={user?.id}
              variant="outline-primary"
            >
              แก้ไขข้อมูลส่วนตัว
            </UserProfileEditButton>
          </ButtonLayout>
        )}
      </Card.Body>
    </Card>
  );
};
