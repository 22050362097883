import { useMutation, useQuery, UseQueryOptions } from "react-query";
import { api } from "../../utils/api";
import { ContentType } from "../../utils/api/tools";
import { Attachment } from "./attachment-type";

export const ATTACHMENT = "attachments";

export const useAttachment = (
  attachmentId?: string,
  option?: UseQueryOptions
) => {
  return useQuery(
    [ATTACHMENT, attachmentId],
    async () => {
      const response = await api.foodSafety.getFile(
        `${ATTACHMENT}/${attachmentId}`
      );

      return response.data;
    },
    {
      enabled: !!attachmentId,
      ...option,
    }
  );
};

export const useUploadAttachment = () => {
  return useMutation<{ attachment: Attachment }, unknown, { file: File }>(
    async (data) => {
      const res = await api.foodSafety.post<{ attachment: Attachment }>(
        ATTACHMENT,
        data,
        {
          contentType: ContentType.FORMDATA,
        }
      );
      return res.data;
    }
  );
};
