import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import AuthInput from "./AuthInput";
import { useChangePassword } from "../../services/auth/auth-query";
import {
  createValidation,
  equal,
  required,
  when,
  cp,
} from "../../utils/field-validation";
import { useTranslation } from "react-i18next";
import appModal from "../../utils/app-modal";
import { useRouter } from "../../utils/helper";

type ChangePasswordFormValue = {
  password: string;
  confirmPassword: string;
};

const vilidation = createValidation<ChangePasswordFormValue>((value) => ({
  password: required("ระบุรหัสผ่าน"),
  confirmPassword: when(() => {
    return !!value.password;
  }, cp(required("ระบุรหัสผ่านอีกครั้ง"), equal(value.password, "รหัสผ่านไม่ถูกต้อง"))),
}));

export default () => {
  const { push } = useRouter();
  const { t } = useTranslation();
  const { mutateAsync: changePassword } = useChangePassword();
  const [errorMessage, setErrorMessage] = useState("");
  const onSubmit = useCallback(
    async (value: ChangePasswordFormValue) => {
      try {
        await changePassword(value);
        appModal.info(t("general.message.success"));
        push("/");
      } catch (e) {
        setErrorMessage(
          t(`errors.${e?.response?.data?.code}`, "Something Went Wrong")
        );
      }
    },
    [changePassword, push, t]
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <div
        className="login-form login-forgot"
        style={{ flex: 1, maxWidth: 400 }}
      >
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">เปลี่ยนรหัสผ่าน</h3>
          <div className="text-muted font-weight-bold">ระบุรหัสผ่านใหม่</div>
        </div>

        <Form
          onSubmit={onSubmit}
          subscription={{ submitting: true }}
          validate={vilidation}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                {errorMessage && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">
                      {errorMessage}
                    </div>
                  </div>
                )}
                <div className="form-group fv-plugins-icon-container">
                  <Field
                    name="password"
                    component={AuthInput}
                    type="password"
                    placeholder="รหัสผ่าน"
                  />
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <Field
                    name="confirmPassword"
                    component={AuthInput}
                    type="password"
                    placeholder="ยืนยันรหัสผ่าน"
                  />
                </div>
                <div className="form-group d-flex flex-wrap flex-center">
                  <button
                    id="kt_login_forgot_submit"
                    type="submit"
                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    disabled={submitting}
                  >
                    Submit
                    {submitting && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                  </button>

                  <Link to="/">
                    <button
                      type="button"
                      id="kt_login_forgot_cancel"
                      className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    >
                      Cancel
                    </button>
                  </Link>
                </div>
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};
