import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const options = {
  userRole: {
    admin: "admin", // ผู้ดูแลระบบ
    province_officer: "province_officer", // เจ้าหน้าที่ระดับจังหวัด
    district_officer: "district_officer", // เจ้าหน้าที่ระดับอำเภอ
  },
  userPosition: {
    operation_pharmacist: "operation_pharmacist", // เภสัชกรปฏิบัติการ
    professional_pharmacist: "professional_pharmacist", // เภสัชกรชำนาญการ
    health_academic_worker: "health_academic_worker", // นักวิชาการสาธารณสุขชำนาญงาน
  },
  shopType: {
    wholesale_market: "wholesale_market", // ตลาดค้าส่ง
    fresh_market: "fresh_market", // ตลาดสด
    flea_market: "flea_market", // ตลาดนัด
    supermarket: "supermarket", // ห้างสรรพสินค้า/ซุปเปอร์มาเก็ต
    restaurant: "restaurant", // ร้านอาหาร
    food_stall: "food_stall,", // แผงลอยจำหน่ายอาหาร
    hospital_kitchen: "hospital_kitchen", // โรงครัวในโรงพยาบาล
    school_canteen: "school_canteen", // โรงอาหารในสถานศึกษา
    child_development_center: "child_development_center", // ศูนย์เด็กเล็ก
    hawker: "hawker", // รถเร่
    other: "other", // อื่นๆ(ระบุเพิ่ม)
  },
  sampleType: {
    red_meat_accelerator: "red_meat_accelerator", // สารเร่งเนื้อแดง
    borax: "borax", // สารบอแรกซ์
    bleach: "bleach", // สารฟอกขาว
    anti_mold: "anti_mold", // สารกันรา
    formaldehyde: "formaldehyde", // ฟอร์มาลีน
    re_frying_oil: "re_frying_oil", // น้ำมันทอดซ้ำ
    gt_test_kit: "gt_test_kit", // ยาฆ่าแมลง (GT Test Kit)
    tm_2_kit: "tm_2_kit", // ยาฆ่าแมลง (TM/2 Kit)
    drinking_water: "drinking_water", // น้ำบริโภค
    restaurant_ice: "restaurant_ice", // น้ำแข็ง(ร้านอาหาร)
    food_stall_ice: "food_stall_ice", // น้ำแข็ง(ร้านแผงลอย)
    food_micro: "food_micro", // จุลินทรีย์(อาหาร)
    hand_micro: "hand_micro", // จุลินทรีย์(มือผู้สัมผัสอาหาร)
    container_micro: "container_micro", // จุลินทรีย์(ภาชนะ)
    other: "other", // อื่นๆ(ระบุเพิ่ม)
    mercury: "mercury", // ปรอท
    hydroquinone: "hydroquinone", // ไฮโดรควิโนน
    steroid: "steroid", // สเตียรอยด์
    retinoic_acid: "retinoic_acid", // กรดเรติโนอิก
  },
  alertStatus: {
    new: "new",
    acknowledged: "acknowledged",
  },
};

export const useOptions = (type: keyof typeof options) => {
  const { t } = useTranslation();
  return useMemo(() => {
    return Object.entries(options[type] as { [key: string]: string }).reduce(
      (res, [key, val]) => {
        res.push({
          value: val,
          label: t(`options.${type}.${key}`),
        });
        return res;
      },
      [] as { value: string; label: string }[]
    );
  }, [t, type]);
};
