import { useMemo } from "react";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router";
import qs from "querystring";
import { setIn } from "final-form";
import { debounce, DebounceSettings } from "lodash";

export const makeRemoteSubmit = (id: string) => {
  return () => {
    document
      .getElementById(id)
      ?.dispatchEvent(new Event("submit", { cancelable: true }));
  };
};

export const useRouter = <TQuery extends any = any>() => {
  const params: any = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const query = useMemo(() => {
    return {
      ...qs.parse(location.search.slice(1)),
    } as TQuery;
  }, [location.search]);

  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      goBack: history.goBack,
      pathname: location.pathname,
      query,
      routeParams: params,
      match,
      location,
    };
  }, [
    history.push,
    history.replace,
    history.goBack,
    location,
    query,
    params,
    match,
  ]);
};

export const parseYupSchema = (schema: any) => async (values: any) => {
  if (typeof schema === "function") {
    schema = schema();
  }
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (err) {
    const errors = err.inner.reduce((formError: any, innerError: any) => {
      return setIn(formError, innerError.path, innerError.message);
    }, {});

    return errors;
  }
};

export const useDebounce = <T extends (...args: any) => any>(
  func: T,
  wait?: number,
  options?: DebounceSettings
) => {
  return useMemo(() => {
    return debounce(func, wait, options);
  }, [func, options, wait]);
};

export const useToOption = (array: any[]) => {
  return useMemo(() => {
    return array.map((val) => ({
      label: val,
      value: val,
    }));
  }, [array]);
};

export type ButtonVariant =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "dark"
  | "light"
  | "link"
  | "outline-primary"
  | "outline-secondary"
  | "outline-success"
  | "outline-danger"
  | "outline-warning"
  | "outline-info"
  | "outline-dark"
  | "outline-light";
