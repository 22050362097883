import React, { CSSProperties, PropsWithChildren } from "react";
import { Tabs } from "react-bootstrap";
import { SelectCallback } from "react-bootstrap/helpers";

type TabsProps = {
  defaultActiveKey?: string;
  id: string;
  style?: CSSProperties;
  activeKey?: string;
  onSelect?: SelectCallback | undefined;
};

export default (props: PropsWithChildren<TabsProps>) => {
  const { style, ...restProps } = props;
  return (
    <Tabs
      style={{
        borderBottom: "1px solid #E4E6EF",
        ...style,
      }}
      {...restProps}
    />
  );
};
