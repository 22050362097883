import React from "react";
import SideMenu from "../../../../../app/components/layout/SideMenu";

export function AsideMenuList({ layoutProps }) {
  return (
    <ul className={`menu-nav ${layoutProps.ulClasses}`}>
      <SideMenu />
    </ul>
  );
}
