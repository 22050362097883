import React, { PropsWithChildren, useCallback } from "react";
import { Field, Form } from "react-final-form";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { createValidation } from "../../../utils/field-validation";
import { makeRemoteSubmit, useToOption } from "../../../utils/helper";
import Button from "../../../components/common/Button";
import Modal, { useModal } from "../../../components/common/Modal";
import ModalFooterButtonLayout from "../../../components/ModalFooterButtonLayout";
import {
  AutoCompleteField,
  DatePickerField,
  SelectField,
} from "../../../components/fields";
import { useDistrictList } from "../../../services/master/master-query";
import { useOptions } from "../../../constants/options";
import { useDownloadSampleFile } from "../../../services/sample/sample-query";

export type ExportExcelFormValue = {
  beginTestDate: string;
  endTestDate: string;
  district: string;
  shopType: string;
  sampleType: string;
};
export type ExportExcelButtonProps = {};

const Grid = styled.div`
  display: grid;
  margin: auto;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 10px;
  .MuiFormControl-marginDense {
    width: 100%;
  }
`;

const validate = createValidation<ExportExcelFormValue>((value) => ({}));

const ShopEditForm = (props: PropsWithChildren<ExportExcelButtonProps>) => {
  const { t } = useTranslation();
  const { props: modalProps, act: modalAct } = useModal();
  const onButtonClick = useCallback(() => {
    modalAct.show();
  }, [modalAct]);

  const { mutate: downloadSampleFile } = useDownloadSampleFile();
  const onSubmit = useCallback(
    async (values: ExportExcelFormValue) => {
      console.log(values);
      downloadSampleFile({
        "filters[begin_test_date]": values.beginTestDate,
        "filters[end_test_date]": values.endTestDate,
        "filters[district]": values.district,
        "filters[shop_type]": values.shopType,
        "filters[sample_type]": values.sampleType,
      });
    },
    [downloadSampleFile]
  );

  const { data: districts = [] } = useDistrictList("นครปฐม");
  const districtOption = useToOption(districts);
  const sampleTypeOptions = useOptions("sampleType");
  const shopTypeOptions = useOptions("shopType");

  return (
    <>
      <Button onClick={onButtonClick} variant="primary">
        Export Excel
      </Button>
      <Modal size="lg" title="Export Excel" {...modalProps}>
        <Form
          onSubmit={onSubmit}
          subscription={{ submitting: true }}
          validate={validate}
          render={({ handleSubmit, submitting }) => {
            return (
              <form id="shopEditForm" onSubmit={handleSubmit}>
                <Grid>
                  <Field
                    component={DatePickerField}
                    name="beginTestDate"
                    label="วันที่เริ่มต้น"
                  />
                  <Field
                    component={DatePickerField}
                    name="endTestDate"
                    label="วันที่สิ้นสุด"
                  />
                  <Field
                    component={AutoCompleteField}
                    name="district"
                    label="อำเภอ"
                    options={districtOption}
                  />
                  <Field
                    component={SelectField}
                    name="shopType"
                    label="ประเภท"
                    items={shopTypeOptions}
                  />
                  <Field
                    component={SelectField}
                    name="sampleType"
                    label="รายการทดสอบ"
                    items={sampleTypeOptions}
                  />
                </Grid>

                <ModalFooterButtonLayout>
                  <Button onClick={modalAct.hide} variant="secondary">
                    {t("general.message.cancel")}
                  </Button>
                  <Button type="submit" isLoading={submitting}>
                    {t("general.message.save")}
                  </Button>
                </ModalFooterButtonLayout>
              </form>
            );
          }}
        />
      </Modal>
    </>
  );
};

ShopEditForm.submit = makeRemoteSubmit("shopEditForm");
export default ShopEditForm;
