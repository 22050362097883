import { useQuery } from "react-query";
import { api } from "../../utils/api";
import {
  ProvinceList,
  DistrictList,
  SubDistrictList,
  PostCodeList,
} from "./master-types";

const MASTER = "master";
const PROVINCE = `${MASTER}/provinces`;
const DISTRICT = `${MASTER}/districts`;
const SUB_DISTRICT = `${MASTER}/sub_districts`;
const POST_CODE = `${MASTER}/post_codes`;

export const useProvinceList = () => {
  return useQuery(
    [MASTER, PROVINCE],
    async () => {
      const response = await api.foodSafety.get<ProvinceList>(PROVINCE);
      return response.data.provinces;
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: () => {},
    }
  );
};

export const useDistrictList = (province?: string) => {
  return useQuery(
    [MASTER, DISTRICT, province],
    async () => {
      if (province) {
        const response = await api.foodSafety.get<DistrictList>(DISTRICT, {
          "filters[province]": province,
        });
        return response.data.districts;
      }
      return [];
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: () => {},
    }
  );
};

export const useSubDistrictList = (province?: string, district?: string) => {
  return useQuery(
    [MASTER, SUB_DISTRICT, province, district],
    async () => {
      if (province && district) {
        const response = await api.foodSafety.get<SubDistrictList>(
          SUB_DISTRICT,
          {
            "filters[province]": province,
            "filters[district]": district,
          }
        );
        return response.data.subDistricts;
      }
      return [];
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: () => {},
    }
  );
};

export const usePostCodeList = (
  province?: string,
  district?: string,
  subDistrict?: string
) => {
  return useQuery(
    [MASTER, POST_CODE, province, district, subDistrict],
    async () => {
      if (province && district && subDistrict) {
        const response = await api.foodSafety.get<PostCodeList>(POST_CODE, {
          "filters[province]": province,
          "filters[district]": district,
          "filters[subDistrict]": subDistrict,
        });
        return response.data.postCodes;
      }
      return [];
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: () => {},
    }
  );
};
