import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Field, Form } from "react-final-form";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import {
  createValidation,
  required,
  when,
} from "../../../utils/field-validation";
import { ButtonVariant, makeRemoteSubmit } from "../../../utils/helper";
import Button from "../../../components/common/Button";
import Modal, { useModal } from "../../../components/common/Modal";
import ModalFooterButtonLayout from "../../../components/ModalFooterButtonLayout";
import {
  DatePickerField,
  InputField,
  SelectField,
} from "../../../components/fields";
import {
  useSampleAdd,
  useSampleEdit,
} from "../../../services/sample/sample-query";
import appModal from "../../../utils/app-modal";
import { Sample } from "../../../services/sample/sample-type";
import { options, useOptions } from "../../../constants/options";
import { OnChange } from "react-final-form-listeners";

export type SampleFormValue = Omit<Sample, "id" | "createdAt" | "updatedAt">;

export type SampleEditFormProps = {
  initialValues?: Partial<SampleFormValue>;
  id?: string;
  title: string;
  variant: ButtonVariant;
};

const Grid = styled.div`
  display: grid;
  margin: auto;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 10px;
  .MuiFormControl-marginDense {
    width: 100%;
  }
`;

const validate = createValidation<SampleFormValue>((value) => ({
  sampleName: required("ระบุชื่อตัวอย่าง"),
  source: required("ระบุแหล่งที่มา"),
  // shopId: required("ระบุแหล่งที่มา"),
  sampleType: required("ระบุรายการทดสอบ"),
  sampleTypeOtherDesc: when(
    () => value?.sampleType === options.sampleType.other,
    required("โปรดระบุรายการทดสอบ")
  ),
  isPass: when(() => typeof value.isPass !== "boolean", required("ระบุผลทสอบ")),
  testDate: required("ระบุวันที่ลงข้อมูล"),
  // testerId: required("ระบุผู้ลงข้อมูล"),
}));

const ShopEditForm = (props: PropsWithChildren<SampleEditFormProps>) => {
  const { title, initialValues, id, children, variant } = props;
  const { t } = useTranslation();
  const { mutateAsync: editSample } = useSampleEdit();
  const { mutateAsync: addSample } = useSampleAdd();
  const { props: modalProps, act: modalAct } = useModal();
  const onButtonClick = useCallback(() => {
    modalAct.show();
  }, [modalAct]);
  const onSubmit = useCallback(
    async (values: SampleFormValue) => {
      try {
        if (id) {
          await editSample({ sampleId: id, values });
        } else {
          await addSample(values);
        }
        appModal.info(t("general.message.success"));
        modalAct.hide();
      } catch {}
    },
    [addSample, editSample, id, modalAct, t]
  );

  const sampleTypeOptions = useOptions("sampleType");
  const isPassOption = useMemo(() => {
    return [
      {
        label: "ผ่าน",
        value: true,
      },
      {
        label: "ไม่ผ่าน",
        value: false,
      },
    ];
  }, []);
  const [sampleTypeOtherDisabled, setSampleTypeOtherDisabled] = useState(false);
  useEffect(() => {
    setSampleTypeOtherDisabled(
      options.sampleType.other !== initialValues?.sampleName
    );
  }, [initialValues]);

  return (
    <>
      <Button onClick={onButtonClick} variant={variant}>
        {children}
      </Button>
      <Modal size="lg" title={title} {...modalProps}>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          subscription={{ submitting: true }}
          validate={validate}
          render={({ handleSubmit, submitting, form }) => {
            return (
              <form id="shopEditForm" onSubmit={handleSubmit}>
                <Grid>
                  <Field
                    component={InputField}
                    name="sampleName"
                    label="ชื่อตัวอย่าง"
                  />
                  <Field
                    component={InputField}
                    name="source"
                    label="แหล่งที่มา"
                  />
                  {/* <Field
                    component={AutoCompleteField}
                    name="shopId"
                    label="แหล่งที่มา"
                    {...shopOption}
                  /> */}
                  <Field
                    component={SelectField}
                    name="sampleType"
                    label="รายการทดสอบ"
                    items={sampleTypeOptions}
                  />
                  <Field
                    component={InputField}
                    name="sampleTypeOtherDesc"
                    label="ระบุรายการทดสอบ"
                    disabled={sampleTypeOtherDisabled}
                  />
                  <OnChange name="sampleType">
                    {(value) => {
                      setSampleTypeOtherDisabled(
                        options.sampleType.other !== value
                      );
                      form.change("sampleTypeOtherDesc", "");
                      return null;
                    }}
                  </OnChange>
                  <Field
                    component={DatePickerField}
                    name="testDate"
                    label="วันที่เก็บตัวอย่าง"
                  />
                  <Field
                    component={SelectField}
                    name="isPass"
                    label="ผลทดสอบ"
                    items={isPassOption}
                  />
                  {/* <Field
                    component={AutoCompleteField}
                    name="testerId"
                    label="ผู้ลงข้อมูล"
                    {...testerOption}
                  /> */}
                </Grid>

                <ModalFooterButtonLayout>
                  <Button onClick={modalAct.hide} variant="secondary">
                    {t("general.message.cancel")}
                  </Button>
                  <Button type="submit" isLoading={submitting}>
                    {t("general.message.save")}
                  </Button>
                </ModalFooterButtonLayout>
              </form>
            );
          }}
        />
      </Modal>
    </>
  );
};

ShopEditForm.submit = makeRemoteSubmit("shopEditForm");
export default ShopEditForm;
