import React from "react";
import { Card } from "react-bootstrap";
import styled from "@emotion/styled";

import AlertTable, { useAlertTable } from "./AlertTable";
import AlertEditButton from "../shared/AlertEditButton";
import { useCurrUser } from "../../../services/auth/auth-query";

const HeaderLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FilterLayout = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  > * {
    margin-left: 10px;
  }
`;

export default () => {
  // table
  const tableProps = useAlertTable("param");
  // end table`
  const { data: currUser } = useCurrUser();

  return (
    <>
      <Card>
        <Card.Header style={{ padding: "1.25rem 2.25rem" }}>
          <HeaderLayout>
            <h4>แจ้งเตือนภัย</h4>
            <FilterLayout>
              <AlertEditButton
                initialValues={{ issuer: currUser, issuerId: currUser?.id }}
                variant="primary"
                title="เพิ่มแจ้งเตือนภัย"
              >
                เพิ่มแจ้งเตือนภัย
              </AlertEditButton>
            </FilterLayout>
          </HeaderLayout>
        </Card.Header>
        <Card.Body style={{ padding: "1.25rem 2.25rem" }}>
          <AlertTable {...tableProps} />
        </Card.Body>
      </Card>
    </>
  );
};
