/* eslint-disable no-extend-native */
import numeral from "numeral";
import dayJS, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import buddhistEra from "dayjs/plugin/buddhistEra";

dayJS.extend(utc);
dayJS.extend(timezone);
dayJS.extend(customParseFormat);
dayJS.extend(buddhistEra);
dayJS.tz.setDefault("Asia/Bangkok");
declare global {
  interface Date {
    toDayjs: (format?: string) => Dayjs;
  }
  interface Number {
    toDayjs: (format?: string) => Dayjs;
    format: (format: string) => string;
  }
  interface String {
    toDayjs: (format?: string) => Dayjs;
    toNumber: () => number;
  }
}

Date.prototype.toDayjs = function(format?: string) {
  return dayJS(this, format);
};

Number.prototype.toDayjs = function(format?: string) {
  return dayJS(this as number, format);
};

Number.prototype.format = function(format: string) {
  return numeral(this).format(format);
};

String.prototype.toDayjs = function(format?: string) {
  return dayJS(this as string, format);
};

String.prototype.toNumber = function() {
  return numeral(this).value();
};
