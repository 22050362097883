import React, { ElementType } from "react";

export const makeField = (Components: ElementType) => (props: any) => {
  /* @ts-ignore */
  const { input, meta, ...restProps } = props;
  return (
    <div>
      <Components {...input} {...restProps} />
      {meta.error && meta.touched && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{meta.error}</div>
        </div>
      )}
    </div>
  );
};
