import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import Dashboard from "./pages/dashboard";
import * as paths from "./constants/path";
import { ShopList, ShopDetail } from "./pages/shop";
import { UserList, UserDetail } from "./pages/user";
import { SampleList, SampleDetail } from "./pages/sample";
import { AlertList, AlertDetail } from "./pages/alert";
import { UserProfileDetail } from "./pages/user-profile";
import { useCurrUser } from "./services/auth/auth-query";
import { UserRole } from "./services/user/user-types";

export default function BasePage() {
  const { data: user } = useCurrUser();
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to={paths.dashboard()} />

        <ContentRoute path={paths.dashboard()} component={Dashboard} />

        <ContentRoute path={paths.shopDetail()} component={ShopDetail} />
        <ContentRoute path={paths.shop()} component={ShopList} />

        {user?.role === UserRole.Admin && (
          <ContentRoute path={paths.userDetail()} component={UserDetail} />
        )}
        {user?.role === UserRole.Admin && (
          <ContentRoute path={paths.user()} component={UserList} />
        )}

        <ContentRoute path={paths.sampleDetail()} component={SampleDetail} />
        <ContentRoute path={paths.sample()} component={SampleList} />

        <ContentRoute path={paths.foodAlertDetail()} component={AlertDetail} />
        <ContentRoute path={paths.foodAlert()} component={AlertList} />

        <ContentRoute
          path={paths.userProfile()}
          component={UserProfileDetail}
        />

        <Redirect to={paths.errorV1()} />
      </Switch>
    </Suspense>
  );
}
