import React from "react";
import { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { makeStyles, CircularProgress } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";
import {
  useAttachment,
  useUploadAttachment,
} from "../../services/attachment/attachment-query";

export type UploadFileProps = {
  value: string; //attachmentId
  onChange: (attachmentId: string) => void;
  onLoading?: (isUploading: boolean) => void;
  style?: CSSProperties;
  className?: string;
  acceptMimeType?: string;
};

const useStyle = makeStyles({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  uploadLayout: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f4f5f6",
    borderRadius: 5,
    cursor: "pointer",
  },
  noPreviewLayout: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  loading: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#ffffff33",
  },
});

const UploadFile = (props: UploadFileProps) => {
  const {
    value,
    onChange,
    onLoading,
    acceptMimeType = "image/jpeg, image/png",
    style,
    className = "",
  } = props;

  const [imgUrl, setImgUrl] = useState<string>();
  const { data: fileData, isLoading: isFileLoading } = useAttachment(value);
  const {
    mutateAsync: uploadFile,
    isLoading: isUplodaFileloading,
  } = useUploadAttachment();

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptMimeType,
    multiple: false,
    noDrag: true,
    maxFiles: 1,
    maxSize: 5 * 1024 * 1024, //5 mb
    onDrop: async (acceptedFiles: File[]) => {
      const uploadList = acceptedFiles.map(async (file) => {
        try {
          const res = await uploadFile({ file: file });
          onChange && onChange(res.attachment.id);
        } catch {}
      });
      Promise.all(uploadList);
    },
    onDropRejected: () => {},
  });

  useEffect(() => {
    try {
      setImgUrl(URL.createObjectURL(fileData));
    } catch {
      setImgUrl(undefined);
    }
  }, [fileData]);

  const isLoading = useMemo(() => {
    return isFileLoading || isUplodaFileloading;
  }, [isFileLoading, isUplodaFileloading]);

  useEffect(() => {
    onLoading && onLoading(isLoading);
  }, [isLoading, onLoading]);

  const classes = useStyle();

  return (
    <div className={`${classes.root} ${className}`} style={style}>
      <div {...getRootProps()} className={classes.uploadLayout}>
        <input type="file" {...getInputProps()} />
        {!isLoading && !imgUrl && (
          <section className={classes.noPreviewLayout}>
            <div>jpg, jpeg, png</div>
            <div>maximum size 5 MB</div>
          </section>
        )}
        {!isLoading && imgUrl && (
          <img
            className={classes.uploadLayout}
            src={imgUrl}
            alt={""}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        )}

        {isLoading && (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={classes.loading}
          >
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadFile;
