import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";
import React, { CSSProperties, useEffect, useRef, useState } from "react";

export type SelectProps = {
  value: string | number;
  onChange:
    | ((
        event: React.ChangeEvent<{
          name?: string | undefined;
          value: unknown;
        }>,
        child: React.ReactNode
      ) => void)
    | undefined;
  label: string;
  items?: { label: string; value: string | number }[];
  style: CSSProperties;
};

export default (props: SelectProps) => {
  const { value, onChange, label, items, style } = props;

  const inputLabel = useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    const { offsetWidth = 0 } = inputLabel.current || {};
    setLabelWidth(offsetWidth);
  }, []);

  return (
    <FormControl variant="outlined" margin="dense" style={style}>
      <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
        {label}
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        input={<OutlinedInput labelWidth={labelWidth} />}
      >
        <MenuItem value="">
          <em>ไม่ระบุ</em>
        </MenuItem>
        {items &&
          items.map((item) => (
            <MenuItem key={item.label} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
