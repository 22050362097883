/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../../../_metronic/layout";
import { BreadCrumbs } from "./BreadCrumbs";

export function HeaderBreadCrumbs({ layoutProps }: any) {
  const location = useLocation();
  const subheader = useSubheader();

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      <div className="d-flex align-items-center flex-wrap mr-1">
        {layoutProps.subheaderMobileToggle && (
          <button
            className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
            id="kt_subheader_mobile_toggle"
          >
            <span />
          </button>
        )}

        <div className="d-flex align-items-baseline mr-5">
          <h5 className="text-dark font-weight-bold my-2 mr-5">
            <>{subheader.title}</>
            {/*<small></small>*/}
          </h5>
        </div>

        <BreadCrumbs items={subheader.breadcrumbs} />
      </div>
    </div>
  );
}
