import React, { PropsWithChildren, useCallback } from "react";
import { Field, Form } from "react-final-form";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { createValidation, required } from "../../../utils/field-validation";
import {
  ButtonVariant,
  makeRemoteSubmit,
  useToOption,
} from "../../../utils/helper";
import Button from "../../../components/common/Button";
import Modal, { useModal } from "../../../components/common/Modal";
import ModalFooterButtonLayout from "../../../components/ModalFooterButtonLayout";
import {
  AutoCompleteField,
  DatePickerField,
  InputField,
} from "../../../components/fields";
import appModal from "../../../utils/app-modal";
import { useAsyncOption } from "../../../components/common/Autocomplete";
import { ShopList } from "../../../services/shop/shop-types";
import { Alert } from "../../../services/alert/alert-types";
import { useAlertAdd, useAlertEdit } from "../../../services/alert/alert-query";
import { useProvinceList } from "../../../services/master/master-query";

export type AlertFormValue = Omit<Alert, "id" | "createdAt" | "updatedAt">;

export type AlertEditFormProps = {
  initialValues?: Partial<AlertFormValue>;
  id?: string;
  title: string;
  variant: ButtonVariant;
};

const Grid = styled.div`
  display: grid;
  margin: auto;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 10px;
  .MuiFormControl-marginDense {
    width: 100%;
  }
`;

const defaultValue = {
  foodType: null,
  trademark: null,
  manufacturingVersion: null,
  foodSerialNumber: null,
  manufacturingDate: null,
  expirationDate: null,
  foodCharacteristic: null,
  foodIngredient: null,
  storageCondition: null,
  packagingCharacteristic: null,
  packingSize: null,
  manufacturerAndImporterInfo: null,
  dealerInfo: null,
  issuerId: null,
  otherInfo: null,
};

const validate = createValidation<AlertFormValue>((value) => ({
  // foodType: required("ระบุประเภทอาหาร"),
  foodName: required("ระบุชื่ออาหาร"),
  // trademark: required("ระบุเครื่องหมายการค้า"),
  // manufacturingVersion: required("ระบุรุ่นของการผลิต"),
  // foodSerialNumber: required("ระบุเลขสารบบอาหาร"),
  // manufacturingDate: required("ระบุวันเดือนปีที่ผลิต"),
  // expirationDate: required("ระบุวันเดือนปีที่หมดอายุ"),
  // foodCharacteristic: required("ระบุลักษณะของอาหาร"),
  // foodIngredient: required("ระบุส่วนประกอบของอาหาร"),
  foodDetail: required("ระบุรายละเอียดผลิตภัณฑ์อาหาร"),
  // storageCondition: required("ระบุสภาพการเก็บรักษา"),
  // packagingCharacteristic: required("ระบุลักษณะบรรจุภัณฑ์"),
  // packingSize: required("ระบุขนาดบรรจุ"),
  // manufacturerAndImporterInfo: required("ระบุชื่อและที่อยู่ของผู้ผลิต/นำเข้า"),
  // dealerInfo: required("ระบุชื่อและที่อยู่ของผู้จำหน่าย"),
  shopId: required("ระบุร้านค้า"),
  alertNo: required("ระบุเลขที่แจ้ง"),
  issueDate: required("ระบุวันเดือนปีที่แจ้ง"),
  // issuerId: required("ระบุผู้แจ้ง"),
  // otherInfo: required('ระบุรายละเอียดอื่นๆ(เพิ่มเติม)'),
}));

const ShopEditForm = (props: PropsWithChildren<AlertEditFormProps>) => {
  const { title, initialValues, id, children, variant } = props;
  const { t } = useTranslation();
  const { mutateAsync: editAlert } = useAlertEdit();
  const { mutateAsync: addAlert } = useAlertAdd();
  const { props: modalProps, act: modalAct } = useModal();
  const onButtonClick = useCallback(() => {
    modalAct.show();
  }, [modalAct]);
  const onSubmit = useCallback(
    async (values: AlertFormValue) => {
      try {
        if (id) {
          await editAlert({
            alertId: id,
            values: { ...defaultValue, ...values },
          });
        } else {
          await addAlert(values);
        }
        appModal.info(t("general.message.success"));
        modalAct.hide();
      } catch {}
    },
    [addAlert, editAlert, id, modalAct, t]
  );

  const shopOption = useAsyncOption<ShopList>(
    "/shops/autocomplete",
    (data) =>
      data.shops.map((shop) => ({ label: shop.shopName, value: shop.id })),
    initialValues && initialValues.shop
      ? [
          {
            label: initialValues.shop!.shopName,
            value: initialValues.shop!.id,
          },
        ]
      : []
  );

  const { data: provinces = [] } = useProvinceList();
  const proviceOption = useToOption(provinces);

  return (
    <>
      <Button onClick={onButtonClick} variant={variant}>
        {children}
      </Button>
      <Modal size="lg" title={title} {...modalProps}>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          subscription={{ submitting: true }}
          validate={validate}
          render={({ handleSubmit, submitting }) => {
            return (
              <form id="shopEditForm" onSubmit={handleSubmit}>
                <Grid>
                  <Field
                    component={InputField}
                    name="foodType"
                    label="ประเภทอาหาร"
                  />
                  <Field
                    component={InputField}
                    name="foodName"
                    label="ชื่อผลิตภัณฑ์"
                  />
                  <Field
                    component={InputField}
                    name="trademark"
                    label="เครื่องหมายการค้า"
                  />
                  <Field
                    component={InputField}
                    name="manufacturingVersion"
                    label="รุ่นของการผลิต"
                  />
                  <Field
                    component={InputField}
                    name="foodSerialNumber"
                    label="เลขสารบบอาหาร/ใบรับจดแจ้ง/ทะเบียน"
                  />
                  <Field
                    component={DatePickerField}
                    name="manufacturingDate"
                    label="วันเดือนปีที่ผลิต"
                  />
                  <Field
                    component={DatePickerField}
                    name="expirationDate"
                    label="วันเดือนปีที่หมดอายุ"
                  />
                  <Field
                    component={InputField}
                    name="foodCharacteristic"
                    label="ลักษณะของผลิตภัณฑ์"
                  />
                  <Field
                    component={InputField}
                    name="foodIngredient"
                    label="ส่วนประกอบของผลิตภัณฑ์"
                  />
                  <Field
                    component={InputField}
                    name="foodDetail"
                    label="รายละเอียดผลิตภัณฑ์"
                  />
                  <Field
                    component={InputField}
                    name="storageCondition"
                    label="สภาพการเก็บรักษา"
                  />
                  <Field
                    component={InputField}
                    name="packagingCharacteristic"
                    label="ลักษณะบรรจุภัณฑ์"
                  />
                  <Field
                    component={InputField}
                    name="packingSize"
                    label="ขนาดบรรจุ"
                  />
                  <Field
                    component={InputField}
                    name="manufacturerAndImporterInfo"
                    label="ชื่อและที่อยู่ของผู้ผลิต/นำเข้า"
                  />
                  <Field
                    component={AutoCompleteField}
                    name="province"
                    label="จังหวัด"
                    options={proviceOption}
                  />
                  <Field
                    component={InputField}
                    name="dealerInfo"
                    label="ชื่อและที่อยู่ของผู้จำหน่าย"
                  />

                  <Field
                    component={AutoCompleteField}
                    name="shopId"
                    label="ร้านค้า"
                    {...shopOption}
                  />
                  <Field
                    component={InputField}
                    name="alertNo"
                    label="เลขที่แจ้ง"
                  />
                  <Field
                    component={DatePickerField}
                    name="issueDate"
                    label="วันเดือนปีที่แจ้ง"
                  />
                  {/* <Field
                    component={AutoCompleteField}
                    name="issuerId"
                    label="ผู้แจ้ง"
                    {...issuerOption}
                  /> */}
                  <Field
                    component={InputField}
                    name="otherInfo"
                    label="รายละเอียดอื่นๆ(เพิ่มเติม)"
                  />

                  {/* <Field
                    component={InputField}
                    name="foodPhoto"
                    label="ภาพถ่ายผลิตภัณฑ์"
                  /> */}
                </Grid>

                <ModalFooterButtonLayout>
                  <Button onClick={modalAct.hide} variant="secondary">
                    {t("general.message.cancel")}
                  </Button>
                  <Button type="submit" isLoading={submitting}>
                    {t("general.message.save")}
                  </Button>
                </ModalFooterButtonLayout>
              </form>
            );
          }}
        />
      </Modal>
    </>
  );
};

ShopEditForm.submit = makeRemoteSubmit("shopEditForm");
export default ShopEditForm;
