import { removeToken } from "../services/auth/auth-helper";
import AppModal from "../utils/app-modal";
import { client } from "../utils/react-query-setting";

export const handleError = (e: any) => {
  const errorCode = e?.response?.data?.code || "notIdentify";
  switch (errorCode) {
    case "401-001": // Invalid access token
    case "401-002": // Access token expired
    case "401-003": // Forbidden
      removeToken();
      client.resetQueries();
      break;
    default:
      AppModal.error(errorCode);
  }
};
