import React from "react";
import { useCallback } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import Button from "../../../components/common/Button";
import Modal, { useModal } from "../../../components/common/Modal";
import ModalFooterButtonLayout from "../../../components/ModalFooterButtonLayout";
import { useAcknowledge } from "../../../services/alert/alert-query";

type AlertAcknowladgeButtonProps = {
  alertId?: string;
  disabled?: boolean;
};
const AlertAcknowladgeButton = (props: AlertAcknowladgeButtonProps) => {
  const { alertId, disabled } = props;
  const { t } = useTranslation();
  const { props: modalProps, act: modalAct } = useModal();
  const { mutateAsync: acknowledge } = useAcknowledge();

  const onSubmit = useCallback(async () => {
    try {
      if (alertId) {
        await acknowledge({ alertId });
      }
    } catch {
    } finally {
      modalAct.hide();
    }
  }, [acknowledge, alertId, modalAct]);

  const onButtonClick = useCallback(() => {
    modalAct.show();
  }, [modalAct]);

  return (
    <>
      <Button onClick={onButtonClick} disabled={disabled}>
        รับทราบ
      </Button>
      <Modal
        title="ยืนยันรับทราบแจ้งเตือน"
        onCancel={modalAct.hide}
        {...modalProps}
      >
        <Form
          onSubmit={onSubmit}
          subscription={{ submitting: true }}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <ModalFooterButtonLayout>
                  <Button onClick={modalAct.hide} variant="secondary">
                    {t("general.message.cancel")}
                  </Button>
                  <Button type="submit" isLoading={submitting}>
                    ตกลง
                  </Button>
                </ModalFooterButtonLayout>
              </form>
            );
          }}
        />
      </Modal>
    </>
  );
};

export default AlertAcknowladgeButton;
