import React, { useCallback, useState } from "react";
import { Modal, ModalProps as BSModalProps } from "react-bootstrap";

type ModalProps = {
  title?: string;
  type?: "primary" | "danger";
} & BSModalProps;

export const useModal = () => {
  const [visibled, setVisibled] = useState(false);
  const show = useCallback(() => {
    setVisibled(true);
  }, []);
  const hide = useCallback(() => {
    setVisibled(false);
  }, []);

  return {
    props: {
      show: visibled,
    },
    act: {
      show,
      hide,
    },
  };
};

export default (props: React.PropsWithChildren<ModalProps>) => {
  const { title, onCancel, onOk, children, ...restProps } = props;
  return (
    <Modal onHide={onCancel || (() => {})} centered {...restProps}>
      {title && <Modal.Header style={{ fontSize: 16 }}>{title}</Modal.Header>}
      {children && <Modal.Body>{children}</Modal.Body>}
    </Modal>
  );
};
