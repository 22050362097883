import styled from "@emotion/styled";

export default styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  > * {
    margin-left: 10px;
  }
`;
