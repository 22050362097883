import React from "react";
import styled from "@emotion/styled";
import { Card } from "react-bootstrap";

import { useRouter } from "../../../utils/helper";
import AlertDeleteButton from "../shared/AlertDeleteButton";
import ALertEditButton from "../shared/AlertEditButton";
import { Redirect } from "react-router";
import * as paths from "../../../constants/path";
import { useTranslation } from "react-i18next";
import AlertAcknowledgeButton from "../shared/AlertAcknowledgeButton";
import { useAlert } from "../../../services/alert/alert-query";
import { useCurrUser } from "../../../services/auth/auth-query";
import { UserRole } from "../../../services/user/user-types";

const DetailLayout = styled.div`
  display: grid;
  max-width: 700px;
  grid-template-columns: 170px 1fr 170px 1fr;
  grid-gap: 10px;
`;
const Label = styled.div`
  font-weight: bold;
`;
const Value = styled.div``;
const ButtonLayout = styled.div`
  display: flex;
  > * {
    margin-right: 10px;
  }
`;

export default () => {
  const { routeParams, goBack } = useRouter();
  const { data: currUser } = useCurrUser();
  const { data: alert, isLoading } = useAlert(routeParams.foodAlertId);
  const { t } = useTranslation();

  if (isLoading) return <div>Loading...</div>;
  if (!isLoading && !alert) return <Redirect to={paths.foodAlert()} />;
  return (
    <Card>
      <Card.Header>
        <h4>แจ้งเตือน</h4>
      </Card.Header>
      <Card.Body>
        <DetailLayout>
          <Label>ประเภทอาหาร</Label>
          <Value>{alert?.foodType}</Value>
          <Label>ชื่ออาหาร</Label>
          <Value>{alert?.foodName}</Value>
          <Label>เครื่องหมายการค้า</Label>
          <Value>{alert?.trademark}</Value>
          <Label>รุ่นของการผลิต</Label>
          <Value>{alert?.manufacturingVersion}</Value>
          <Label>เลขสารบบอาหาร</Label>

          <Value>{alert?.foodSerialNumber}</Value>
          <Label>วันเดือนปีที่ผลิต</Label>
          <Value>
            {alert?.manufacturingDate?.toDayjs().format("DD/MM/BBBB")}
          </Value>
          <Label>วันเดือนปีที่หมดอายุ</Label>
          <Value>{alert?.expirationDate?.toDayjs().format("DD/MM/BBBB")}</Value>
          <Label>ลักษณะของอาหาร</Label>
          <Value>{alert?.foodCharacteristic}</Value>
          <Label>ส่วนประกอบของอาหาร</Label>
          <Value>{alert?.foodIngredient}</Value>
          <Label>สภาพการเก็บรักษา</Label>
          <Value>{alert?.storageCondition}</Value>
          <Label>ลักษณะบรรจุภัณฑ์</Label>
          <Value>{alert?.packagingCharacteristic}</Value>
          <Label>ขนาดบรรจุ</Label>
          <Value>{alert?.packingSize}</Value>
          <Label>ชื่อและที่อยู่ของผู้ผลิต/นำเข้า</Label>
          <Value>{alert?.manufacturerAndImporterInfo}</Value>
          <Label>จังหวัด</Label>
          <Value>{alert?.province}</Value>
          <Label>ชื่อและที่อยู่ของผู้จำหน่าย</Label>
          <Value>{alert?.dealerInfo}</Value>
          <Label>รายละเอียดผลิตภัณฑ์อาหาร</Label>
          <Value>{alert?.foodDetail}</Value>
          <Label>ร้านค้า</Label>
          <Value>{alert?.shop.shopName}</Value>
          <Label>เลขที่แจ้ง</Label>
          <Value>{alert?.alertNo}</Value>
          <Label>วันเดือนปีที่แจ้ง</Label>
          <Value>{alert?.issueDate?.toDayjs().format("DD/MM/BBBB")}</Value>
          <Label>ผู้แจ้ง</Label>
          <Value>{`${alert?.issuer.firstname} ${alert?.issuer.lastname}`}</Value>
          <Label>รายละเอียดอื่นๆ(เพิ่มเติม)</Label>
          <Value>{alert?.otherInfo}</Value>
          <Label>สถานะ</Label>
          <Value>{t(`options.alertStatus.${alert?.status}`, "-")}</Value>

          {/* <Label>ภาพถ่ายผลิตภัณฑ์</Label>
          <Value>{alert?.foodPhoto}</Value> */}
        </DetailLayout>
        <ButtonLayout style={{ marginTop: 25 }}>
          {currUser?.role !== UserRole.DistrictOfficer && (
            <AlertAcknowledgeButton
              alertId={alert?.id}
              disabled={alert?.status === "acknowledged"}
            />
          )}
          <ALertEditButton
            title="แก้ไขแจ้งเตือน"
            initialValues={alert}
            id={alert?.id}
            variant="outline-primary"
          >
            แก้ไขข้อมูลตัวอย่าง
          </ALertEditButton>
          <AlertDeleteButton id={alert?.id} onDeleteSuccess={goBack}>
            ลบแจ้งเตือน
          </AlertDeleteButton>
        </ButtonLayout>
      </Card.Body>
    </Card>
  );
};
