import { useMutation, useQuery, useQueryClient } from "react-query";
import { AlertFormValue } from "../../pages/alert/shared/AlertEditButton";
import { api } from "../../utils/api";
import { Paging } from "../shared-types";
import { Alert, AlertList } from "./alert-types";

const ALERT_URL = "alerts";

export const useAlertList = (
  params: Partial<Paging> & { [key: string]: any }
) => {
  return useQuery([ALERT_URL, params], async () => {
    const response = await api.foodSafety.get<AlertList>(ALERT_URL, params);
    return response.data;
  });
};

export const useAlert = (id: string) => {
  return useQuery([ALERT_URL, id], async () => {
    const response = await api.foodSafety.get<{ alert: Alert }>(
      `${ALERT_URL}/${id}`
    );
    return response.data.alert;
  });
};

export const useAlertEdit = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    unknown,
    { alertId: string; values: AlertFormValue }
  >(
    ({ alertId, values }) => {
      return api.foodSafety.patch(`${ALERT_URL}/${alertId}`, values);
    },
    {
      onSuccess: (data, { alertId }) => {
        queryClient.invalidateQueries([ALERT_URL, alertId]);
      },
    }
  );
};

export const useAlertAdd = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, AlertFormValue>(
    (params) => {
      return api.foodSafety.post(ALERT_URL, params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ALERT_URL]);
      },
    }
  );
};

export const useAlertDelete = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, string>(
    (alertId: string) => {
      return api.foodSafety.delete(`${ALERT_URL}/${alertId}`);
    },
    {
      onSuccess: (data, alertId) => {
        queryClient.removeQueries([ALERT_URL, alertId]);
      },
    }
  );
};

export const useAcknowledge = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ alertId }: { alertId: string }) => {
      return api.foodSafety.patch(`${ALERT_URL}/${alertId}/acknowledge`);
    },
    {
      onSuccess: (data, { alertId }) => {
        queryClient.invalidateQueries([ALERT_URL, alertId]);
      },
    }
  );
};
