import React, { useCallback, useState } from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useDebounce } from "../../utils/helper";
import { api } from "../../utils/api";

type OptionType = { value: string };
type SuggesstionInputProps = {
  value: any;
  onChange: any;
  label?: string;
  optionUrl: string;
  optionNormalizer: (response: any) => OptionType[];
};

export default (props: SuggesstionInputProps) => {
  const { optionUrl, onChange, value, label, optionNormalizer } = props;
  const [options, setOptions] = useState<OptionType[]>([]);

  const search = useDebounce(async (value: string) => {
    if (value) {
      const response = await api.foodSafety.get<any>(optionUrl, {
        q: value,
        limit: 10,
      });
      const options = optionNormalizer(response.data);
      setOptions(options);
    } else {
      setOptions([]);
    }
  }, 200);

  const onInputChange = useCallback(
    (e) => {
      const value = e?.target?.value;
      if (value !== undefined) {
        onChange(value);
        search(value);
      }
    },
    [onChange, search]
  );

  return (
    <Autocomplete
      freeSolo
      disableClearable
      options={options}
      inputValue={value}
      onInputChange={onInputChange}
      getOptionLabel={(option) => option.value}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          margin="dense"
        />
      )}
      //@ts-ignore
      onChange={(_e: any, opt: OptionType) => {
        opt && onChange(opt.value);
      }}
    />
  );
};
