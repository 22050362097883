import axios, { AxiosResponse } from "axios";
import dayjs from "dayjs";
import humps from "humps";
import { getToken } from "../../services/auth/auth-helper";
import { ContentType, customRequestData, deepLoop } from "./tools";

const createClient = () => {
  const ax = axios.create();
  ax.interceptors.request.use((request: any) => {
    request.url = `${process.env.REACT_APP_API_HOST}/${request.url}`;
    request.headers.common["Authorization"] = `Bearer ${getToken()}`;
    if (request.params) {
      request.params = deepLoop(request.params, modifyRequestData);
    }
    if (request.data) {
      if (request.headers["Content-Type"] !== ContentType.FORMDATA) {
        request.data = deepLoop(request.data, modifyRequestData);
        request.data = humps.decamelizeKeys(request.data);
      }
      customRequestData(request);
    }
    return request;
  });
  ax.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      if (response.status === 204) return response;
      if (response.headers["content-type"].includes("application/json")) {
        response.data = humps.camelizeKeys(response.data);
        // deepLoop(response.data, data => {
        //   return data
        // })
      }
      return response;
    },
    (error: any) => Promise.reject(error)
  );
  return ax;
};

const modifyRequestData = (data: any) => {
  if (dayjs.isDayjs(data)) {
    return data.format();
  }
  return data;
};

export const foodSafetyClient = createClient();

export const foodSafetyApiWraper = async (method: Promise<AxiosResponse>) => {
  try {
    const res = await method;
    return Promise.resolve(res);
  } catch (e) {
    // const { response, message } = e;
    // const { data } = response || {};
    // const { error } = data || {};
    // const { message: errorMessage } = error || {};
    // return Promise.reject(errorMessage || message || e);
    return Promise.reject(e);
  }
};
