import React, { useMemo } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_metronic/_helpers";

type AsideMenuButton = {
  path: string;
  title: string;
  iconUrl?: string;
  hasDot?: boolean;
};

export default (props: AsideMenuButton) => {
  const { path, title, iconUrl, hasDot } = props;
  const location = useLocation();
  const isMenuActive = useMemo(() => {
    return checkIsActive(location, path)
      ? ` ${"menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  }, [location, path]);

  return (
    <li className={`menu-item ${isMenuActive}`} aria-haspopup="true">
      <NavLink className="menu-link" to={path}>
        {hasDot && (
          <i className="menu-bullet menu-bullet-dot">
            <span />
          </i>
        )}
        {iconUrl && (
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl(iconUrl)} />
          </span>
        )}
        <span className="menu-text">{title}</span>
      </NavLink>
    </li>
  );
};
