import React from "react";
import { Card } from "react-bootstrap";
import styled from "@emotion/styled";
import PieChart from "./PieChart";
import BarChart from "./BarChart";

const ChartLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

export default () => (
  <Card>
    <Card.Header>
      <h4>ภาพรวม</h4>
    </Card.Header>
    <Card.Body>
      <ChartLayout>
        <PieChart />
        <hr style={{ width: "100%", margin: "50px 0 " }} />
        <BarChart />
      </ChartLayout>
    </Card.Body>
  </Card>
);
