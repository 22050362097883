import React, { CSSProperties, PropsWithChildren } from "react";
import { Tab } from "react-bootstrap";

type TabProps = {
  eventKey: string;
  title: string;
  style?: CSSProperties;
};

export default (props: PropsWithChildren<TabProps>) => {
  const { style, ...restProps } = props;
  return (
    <Tab
      style={{
        border: "1px solid #E4E6EF",
        borderTop: 0,
        ...style,
      }}
      {...restProps}
    />
  );
};
