/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../../_metronic/layout/_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { DropdownTopbarItemToggler } from "../../../../_metronic/_partials/dropdowns";
import { useCurrUser, useLogout } from "../../../services/auth/auth-query";
import * as paths from "../../../constants/path";
import AuthImg from "../../common/AuthImg";

export function UserProfileDropdown() {
  const { data: user } = useCurrUser();
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);
  const { mutate } = useLogout();
  const onLogoutClick = useCallback(() => {
    mutate();
  }, [mutate]);

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          }
        >
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            {user?.firstname} {user?.lastname}
          </span>

          <span className="symbol symbol-35 symbol-light-success">
            <div
              className="symbol-label"
              // style={{ backgroundImage: `url(${user?.pic})` }}
            >
              <AuthImg imgId={user?.avatarId} />
            </div>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-8 rounded-top">
                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                  <img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt="" />
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                  {user?.firstname} {user?.lastname}
                </div>
              </div>
              <div className="separator separator-solid"></div>
            </>
          )}

          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
              style={{
                backgroundColor: "#1a1a27",
                // backgroundImage: `url(${toAbsoluteUrl(
                //   "/media/misc/bg-1.jpg"
                // )})`,
              }}
            >
              <div className="symbol bg-white-o-15 mr-3">
                <div
                  className="symbol-label"
                  // style={{ backgroundImage: `url(${user?.pic})` }}
                >
                  <AuthImg imgId={user?.avatarId} />
                </div>
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                {user?.firstname} {user?.lastname}
              </div>
            </div>
          )}
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <Link
            to={paths.userProfile()}
            className="navi-item px-8 cursor-pointer"
          >
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">โปรไฟล์</div>
              </div>
            </div>
          </Link>

          <div className="navi-separator mt-3"></div>

          <div className="navi-footer  px-8 py-5">
            <Link
              to={paths.changePassword()}
              className="btn btn-light-primary font-weight-bold"
            >
              เปลี่ยนรหัสผ่าน
            </Link>

            <div className="btn  font-weight-bold" onClick={onLogoutClick}>
              ออกจากระบบ
            </div>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
