import { hideModal, showModal } from "../../redux/app-reducer";
import store from "../../redux/store";

const error = (message: string) => {
  store.dispatch(showModal({ message, title: "Something Went Wrong" }));
};
const info = (message: string) => {
  store.dispatch(showModal({ message, title: "Info" }));
};
const confirm = (message: string, onConfirm: () => void) => {
  store.dispatch(showModal({ message, title: "Confirm", onConfirm }));
};
const hide = () => {
  store.dispatch(hideModal());
};
export default {
  error,
  info,
  confirm,
  hide,
};
