import React, { PropsWithChildren, useCallback } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import Button from "../../../components/common/Button";
import Modal, { useModal } from "../../../components/common/Modal";
import ModalFooterButtonLayout from "../../../components/ModalFooterButtonLayout";
import { useSampleDelete } from "../../../services/sample/sample-query";

type DeleteModalProps = {
  id?: string;
  onDeleteSuccess?: () => void;
};
export default (props: PropsWithChildren<DeleteModalProps>) => {
  const { id, onDeleteSuccess, children } = props;
  const { mutateAsync: deleteSample } = useSampleDelete();
  const { props: deleteModalProps, act: deleteModalAct } = useModal();
  const onDeleteClick = useCallback(() => {
    deleteModalAct.show();
  }, [deleteModalAct]);
  const onDeleteSummit = useCallback(async () => {
    if (id) {
      try {
        await deleteSample(id);
        onDeleteSuccess && onDeleteSuccess();
      } catch {}
    }
    deleteModalAct.hide();
  }, [deleteModalAct, deleteSample, id, onDeleteSuccess]);

  const { t } = useTranslation();
  return (
    <>
      <Button variant="outline-danger" onClick={onDeleteClick}>
        {children}
      </Button>
      <Modal
        type="danger"
        title="ลบข้อมูลตัวอย่าง"
        onCancel={deleteModalAct.hide}
        {...deleteModalProps}
      >
        <Form
          onSubmit={onDeleteSummit}
          subscription={{ submitting: true }}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <ModalFooterButtonLayout>
                  <Button onClick={deleteModalAct.hide} variant="secondary">
                    {t("general.message.cancel")}
                  </Button>
                  <Button variant="danger" type="submit" isLoading={submitting}>
                    {t("shop.modal.delete.confirmButton")}
                  </Button>
                </ModalFooterButtonLayout>
              </form>
            );
          }}
        />
      </Modal>
    </>
  );
};
