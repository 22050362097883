import { useMutation, useQuery, useQueryClient } from "react-query";
import { UserFormValue } from "../../pages/user/shared/UserEditButton";
import { api } from "../../utils/api";
import { Paging } from "../shared-types";
import { User, UserAndTempPassword, UserList } from "./user-types";
import { useCurrUser, CURRENT_UESR } from "../auth/auth-query";

const USER = "users";

export const useUserList = (
  params: Partial<Paging> & { [key: string]: any }
) => {
  return useQuery([USER, params], async () => {
    const response = await api.foodSafety.get<UserList>(USER, params);
    return response.data;
  });
};

export const useUser = (id: string) => {
  return useQuery([USER, id], async () => {
    const response = await api.foodSafety.get<{ user: User }>(`${USER}/${id}`);
    return response.data.user;
  });
};

export const useUserEdit = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    unknown,
    { userId: string; values: UserFormValue }
  >(
    ({ userId, values }) => {
      return api.foodSafety.patch(`${USER}/${userId}`, values);
    },
    {
      onSuccess: (data, { userId }) => {
        queryClient.invalidateQueries([USER, userId]);
      },
    }
  );
};

export const useUserAdd = () => {
  const queryClient = useQueryClient();
  return useMutation<UserAndTempPassword, unknown, UserFormValue>(
    async (params) => {
      const res = await api.foodSafety.post<{ user: UserAndTempPassword }>(
        USER,
        params
      );
      return res.data.user;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([USER]);
      },
    }
  );
};

export const useUserDelete = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, string>(
    (userId: string) => {
      return api.foodSafety.delete(`${USER}/${userId}`);
    },
    {
      onSuccess: (data, userId) => {
        queryClient.removeQueries([USER, userId]);
      },
    }
  );
};

export const useResetPassword = () => {
  return useMutation<UserAndTempPassword, unknown, { userId: string }>(
    async ({ userId }) => {
      const response = await api.foodSafety.patch<{
        user: UserAndTempPassword;
      }>(`${USER}/${userId}/reset_password`);
      return response.data.user;
    }
  );
};

export const useCurrUserEdit = () => {
  const queryClient = useQueryClient();
  const { data: user } = useCurrUser();
  return useMutation<User, unknown, { values: UserFormValue }>(
    async ({ values }) => {
      const response = await api.foodSafety.patch<{ user: User }>(
        `${USER}/${user?.id}`,
        values
      );
      return response.data.user;
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(CURRENT_UESR, data);
      },
    }
  );
};
