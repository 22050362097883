import {
  useMutation,
  MutationOptions,
  useQuery,
  QueryOptions,
  useQueryClient,
} from "react-query";
import { api } from "../../utils/api";
import { getToken, removeToken, setToken } from "./auth-helper";
import { LoginType, CurrentUser, ChangePasswordType } from "./auth-types";

const AUTH = "auth";
export const CURRENT_UESR = `${AUTH}/current_user`;
const CHANGE_PASSWORD = `${AUTH}/change_password`;

export const useLogin = (
  options?: MutationOptions<unknown, unknown, LoginType>
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (params) => {
      return api.foodSafety.post<{ accessToken: string }>(
        "auth/sign_in",
        params
      );
    },
    {
      onSuccess: (response) => {
        const token = response.data.accessToken;
        setToken(token);
        queryClient.refetchQueries(CURRENT_UESR);
      },
      ...options,
    }
  );
};

export const useLogout = () => {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return Promise.resolve("logout");
    },
    {
      onSuccess: () => {
        removeToken();
        queryClient.resetQueries();
      },
    }
  );
};

export const useCurrUser = (
  options?: QueryOptions<CurrentUser | undefined>
) => {
  return useQuery<CurrentUser | undefined>(
    CURRENT_UESR,
    async () => {
      if (getToken()) {
        const response = await api.foodSafety.get<{ currentUser: CurrentUser }>(
          CURRENT_UESR
        );
        return response.data.currentUser;
      }
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: () => {
        removeToken();
      },
      retry: 0,
      ...options,
    }
  );
};

export const useChangePassword = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, ChangePasswordType>(
    (param) => {
      return api.foodSafety.patch(CHANGE_PASSWORD, param);
    },
    {
      onError: () => {},
      onSuccess: () => {
        queryClient.refetchQueries(CURRENT_UESR);
      },
    }
  );
};

export const useRegister = (
  options?: MutationOptions<unknown, string, string>
) => {
  return useMutation((value: string) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(`error`);
      }, 1000);
    });
  }, options);
};
