import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { Card } from "react-bootstrap";

import { useResetPassword, useUser } from "../../../services/user/user-query";
import { useRouter } from "../../../utils/helper";
import UserDeleteButton from "../shared/UserDeleteButton";
import * as pahts from "../../../constants/path";
import { Redirect } from "react-router";
import UserEditButton from "../shared/UserEditButton";
import { useTranslation } from "react-i18next";
import Button from "../../../components/common/Button";
import appModal from "../../../utils/app-modal";
import AuthImg from "../../../components/common/AuthImg";

const DetailLayout = styled.div`
  display: grid;
  max-width: 700px;
  grid-template-columns: 150px 1fr;
  grid-gap: 10px;
`;
const Label = styled.div`
  font-weight: bold;
`;
const Value = styled.div``;
const ButtonLayout = styled.div`
  display: flex;
  > * {
    margin-right: 10px;
  }
`;

export default () => {
  const { t } = useTranslation();
  const { routeParams, goBack } = useRouter();
  const { data: user, isLoading } = useUser(routeParams.userId);
  const { mutateAsync: resetPassword } = useResetPassword();
  const resetPasswordClick = useCallback(() => {
    appModal.confirm("ยืนยันการรีเซ็ทรหัสผ่าน", async () => {
      try {
        const res = await resetPassword({ userId: routeParams.userId });
        appModal.info(`รหัสผ่านชั่วคราว: ${res.temporaryPassword}`);
      } catch {}
    });
  }, [resetPassword, routeParams.userId]);

  if (isLoading) return <div>Loading...</div>;
  if (!isLoading && !user) return <Redirect to={pahts.user()} />;
  return (
    <Card>
      <Card.Header>
        <h4>ผู้ใช้งาน</h4>
      </Card.Header>
      <Card.Body>
        <AuthImg
          imgId={user?.avatarId}
          style={{ width: 150, height: 150, marginBottom: 20 }}
        />
        <DetailLayout>
          <Label>รหัสประจำตัวประชาชน</Label>
          <Value>{user?.citizenNumber || "-"}</Value>
          <Label>ชื่อ - นามสกุล</Label>
          <Value>
            {user?.firstname || "-"} {user?.lastname || ""}
          </Value>
          <Label>หมายยเลขโทรศัพท์</Label>
          <Value>{user?.phoneNumber || "-"}</Value>
          <Label>ตำแหน่ง</Label>
          <Value>{user?.positionName || "-"}</Value>
          <Label>สถานที่ทำงาน</Label>
          <Value>{user?.workGroupName || "-"}</Value>
          <Label>ชื่อสถานที่</Label>
          <Value>{user?.workPlaceName || "-"}</Value>
          <Label>อำเภอ</Label>
          <Value>{user?.workPlaceDistrict || "-"}</Value>
          <Label>จังหวัด</Label>
          <Value>{user?.workPlaceProvince || "-"}</Value>
          <Label>บทบาทการใช้งาน</Label>
          <Value>{t(`options.userRole.${user?.role}`, "-")}</Value>
        </DetailLayout>
        <ButtonLayout style={{ marginTop: 25 }}>
          <Button variant="outline-warning" onClick={resetPasswordClick}>
            รีเซ็ทรหัสผ่าน
          </Button>
          <UserEditButton
            title="แก้ไขผู้ใช้งาน"
            initialValues={user}
            id={user?.id}
            variant="outline-primary"
          >
            แก้ไขผู้ใช้งาน
          </UserEditButton>
          <UserDeleteButton id={user?.id} onDeleteSuccess={goBack}>
            ลบผู้ใช้งาน
          </UserDeleteButton>
        </ButtonLayout>
      </Card.Body>
    </Card>
  );
};
