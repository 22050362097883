import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/common/Button";

import Table, {
  TablePropsType,
  useTable,
  useTableWithLocalState,
} from "../../../components/common/Table";
import { Shop } from "../../../services/shop/shop-types";
import { useRouter } from "../../../utils/helper";
import * as paths from "../../../constants/path";
import { useShopList } from "../../../services/shop/shop-query";

export const useShopTable = (mode?: "param" | "state", extendedParam?: any) => {
  const { push } = useRouter();
  const modeFn = mode === "param" ? useTable : useTableWithLocalState;
  const { props } = modeFn({
    routePath: paths.shop,
    query: useShopList,
    itemKey: "shops",
    extendedParam,
  });
  const onViewClick = useCallback(
    (row: any) => {
      push(
        paths.sampleDetail({
          routeParam: {
            sampleId: row.id,
          },
        })
      );
    },
    [push]
  );

  return {
    ...props,
    onViewClick,
  };
};

const useColumns = ({ onViewClick, page, sizePerPage }: any) => {
  const { t } = useTranslation();
  return useMemo(
    () => [
      {
        dataField: "no",
        text: t("shop.table.column.no"),
        formatter: (text: string, row: any, index: number) => {
          return ((page || 1) - 1) * (sizePerPage || 10) + (index + 1);
        },
      },
      {
        dataField: "shopName",
        text: t("shop.table.column.name"),
      },
      {
        dataField: "shopType",
        text: t("shop.table.column.type"),
        formatter: (text: string) => {
          return t(`options.shopType.${text}`);
        },
      },
      {
        dataField: "ownerName",
        text: t("shop.table.column.ownerName"),
      },
      {
        dataField: "phoneNumber",
        text: t("shop.table.column.phoneNumber"),
      },
      {
        dataField: "address",
        text: t("shop.table.column.address"),
        formatter: (text: string, row: Shop) => {
          return `${row.address} ${row.subDistrict} ${row.district} ${row.province} ${row.postCode}`;
        },
      },
      {
        dataField: "action",
        text: "",
        formatter: (value: any, row: any, index: number) => {
          return (
            <>
              <Button variant="link" onClick={onViewClick.bind(null, row)}>
                ดูรายละเอียด
              </Button>
            </>
          );
        },
        classes: "text-right pr-0",
        headerClasses: "text-right pr-3",
        style: {
          minWidth: "110px",
        },
      },
    ],
    [onViewClick, page, sizePerPage, t]
  );
};

type ShopTableProps = {
  onViewClick: boolean | ((row: any) => void);
} & Omit<TablePropsType, "columns">;

export default (props: ShopTableProps) => {
  const { onViewClick, ...restProps } = props;
  const columns = useColumns({
    onViewClick,
    page: restProps.paginationOptions.page,
    sizePerPage: restProps.paginationOptions.sizePerPage,
  });
  return <Table {...restProps} columns={columns} />;
};
