import React, { useCallback } from "react";
import { Card } from "react-bootstrap";
import styled from "@emotion/styled";

import ShopTable, { useShopTable } from "./ShopTable";
import ShopEditButton from "../shared/ShopEditButton";
import * as paths from "../../../constants/path";
import { useTranslation } from "react-i18next";
import { useRouter } from "../../../utils/helper";
import Input from "../../../components/common/Input";

const HeaderLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FilterLayout = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  > * {
    margin-left: 10px;
  }
`;

export default () => {
  const { t } = useTranslation();
  const { push, query } = useRouter();

  // table
  const tableProps = useShopTable("param");
  const onShowShopClick = useCallback(
    (row) => {
      push(
        paths.shopDetail({
          routeParam: {
            shopId: row.id,
          },
        })
      );
    },
    [push]
  );
  // end table`

  const onSearch = useCallback(
    (event) => {
      if (event.key === "Enter") {
        const value = event.target.value;
        push(
          paths.shop({
            queryParam: {
              ...query,
              q: value,
              page: 1,
            },
          })
        );
      }
    },
    [push, query]
  );

  return (
    <>
      <Card>
        <Card.Header style={{ padding: "1.25rem 2.25rem" }}>
          <HeaderLayout>
            <h4>{t("shop.title")}</h4>
            <FilterLayout>
              {/* <Select
                value={query["filters[shop_type]"] || ""}
                onChange={onFilterTypeChange}
                label={t("shop.filter.type")}
                style={{ minWidth: 100, marginTop: 5 }}
                items={shopTypeOptions}
              /> */}
              <Input
                onKeyDown={onSearch}
                placeholder="ค้นหา, ชื่อ, ประเภท, เจ้าของ"
                style={{ marginTop: 5 }}
              />
              <ShopEditButton
                title={t("shop.modal.add.title")}
                varient="primary"
              >
                {t("shop.button.add")}
              </ShopEditButton>
            </FilterLayout>
          </HeaderLayout>
        </Card.Header>
        <Card.Body style={{ padding: "1.25rem 2.25rem" }}>
          <ShopTable {...tableProps} onViewClick={onShowShopClick} />
        </Card.Body>
      </Card>
    </>
  );
};
