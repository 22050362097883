import "date-fns";
import React, { useCallback, useMemo } from "react";
import DayjsUtils from "@date-io/dayjs";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import th from "dayjs/locale/th";
import dayjs, { Dayjs } from "dayjs";

class DateUtil extends DayjsUtils {
  format = (date: Dayjs, formatKey: any) => {
    return date.format(formatKey);
  };
  getDayText = (date: Dayjs) => {
    return date.format("DD");
  };
  getCalendarHeaderText = (date: Dayjs) => {
    return date.format("MMMM BBBB");
  };
  getDatePickerHeaderText = (date: Dayjs) => {
    return date.format("ddd, MMM DD");
  };
  getYearText = (date: Dayjs) => {
    return date.format("BBBB");
  };
}

type DatePickerProps = {
  value?: Dayjs | string;
  onChange: (date: string | null) => void;
  label?: string;
};

export default (props: DatePickerProps) => {
  const { value, onChange, label } = props;
  const parseValue = useMemo(() => {
    if (!value) {
      // const date = dayjs();
      // onChange(date.format());
      // return date;
      onChange(null);
      return null;
    }
    return dayjs(value);
  }, [onChange, value]);
  const customOnChange = useCallback(
    (value) => {
      onChange(value.format());
    },
    [onChange]
  );
  return (
    <MuiPickersUtilsProvider utils={DateUtil} locale={th}>
      <KeyboardDatePicker
        // disableToolbar
        // variant="inline"
        showTodayButton
        format="DD/MM/BBBB"
        margin="dense"
        inputVariant="outlined"
        label={label}
        value={parseValue}
        onChange={customOnChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
};
