import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    modal: {
      title: "",
      show: false,
      message: "",
      onConfirm: () => {},
    },
  },
  reducers: {
    showModal: (
      state,
      action: PayloadAction<{
        title: string;
        message: string;
        onConfirm?: () => void;
      }>
    ) => {
      const { message, title, onConfirm } = action.payload;
      state.modal.title = title;
      state.modal.message = message;
      state.modal.show = true;
      state.modal.onConfirm = onConfirm || (() => {});
    },
    hideModal: (state) => {
      state.modal.show = false;
      state.modal.title = "";
      state.modal.message = "";
      state.modal.onConfirm = () => {};
    },
  },
});

export const { showModal, hideModal } = appSlice.actions;
export default appSlice.reducer;
