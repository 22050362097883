import React, { useCallback, useEffect, useMemo, useState } from "react";
import BootstrapTable, {
  ColumnDescription,
  PaginationOptions,
  TableChangeState,
} from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { QueryObserverResult } from "react-query";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../_metronic/_helpers";
import { Pagination } from "../../../_metronic/_partials/controls";
import { useRouter } from "../../utils/helper";
import { PathOption } from "../../constants/path";
import { Paging } from "../../services/shared-types";

export type TablePropsType = {
  paginationOptions: PaginationOptions;
  listLoading: boolean;
  data: any;
  columns: ColumnDescription[];
  onChange: (state: TableChangeState<any>) => void;
};

const sizePerPageList = Array.from({ length: 5 }).map((v, i) => ({
  text: `${(i + 1) * 2}`,
  value: (i + 1) * 2,
}));

export const useTableWithLocalState = ({
  query,
  itemKey,
  extendedParam,
}: {
  query: (pageParam: Partial<Paging>) => QueryObserverResult<any, any>;
  itemKey: string;
  extendedParam?: any;
}) => {
  const [{ page, perPage }, setPaging] = useState<{
    page: number;
    perPage: number;
  }>({
    page: 1,
    perPage: 8,
  });

  const onPageChange = useCallback(
    (newPage) => {
      setPaging({
        perPage: newPage.perPage || perPage,
        page: (newPage.perPage || perPage) !== perPage ? 1 : newPage.page,
      });
    },
    [perPage]
  );

  const { data: response, isLoading, refetch } = query({
    page,
    per_page: perPage,
    ...extendedParam,
  });

  const paginationOptions = useMemo(() => {
    return {
      sizePerPage: perPage,
      page,
      totalSize: response?.meta?.paging?.totalCount || 0,
    };
  }, [page, perPage, response]);

  const onChange = useCallback(
    (state: TableChangeState<any>) => {
      const { sizePerPage: perPage, page } = state;
      onPageChange({
        perPage,
        page: page,
      });
    },
    [onPageChange]
  );

  return {
    props: {
      data: response?.[itemKey],
      onChange,
      paginationOptions,
      listLoading: isLoading,
    },
    acts: {
      refetch,
    },
  };
};
export const useTable = ({
  routePath,
  query,
  itemKey,
  extendedParam,
}: {
  routePath: (option?: PathOption<any, any> | undefined) => string;
  query: (pageParam: Partial<Paging>) => QueryObserverResult<any, any>;
  itemKey: string;
  extendedParam?: any;
}) => {
  const router = useRouter();
  const { page, perPage, ...restParams } = useMemo(() => {
    const { page = 1, perPage = 8, ...restParams } = router.query;
    return {
      ...restParams,
      page: Number(page),
      perPage: Number(perPage),
    };
  }, [router.query]);
  const onPageChange = useCallback(
    (newPage) => {
      router.push(
        routePath({
          queryParam: {
            ...restParams,
            perPage: newPage.perPage || perPage,
            page: (newPage.perPage || perPage) !== perPage ? 1 : newPage.page,
          },
        })
      );
    },
    [router, routePath, perPage, restParams]
  );

  const { data: response, isLoading, refetch } = query({
    ...restParams,
    ...extendedParam,
    page,
    per_page: perPage,
  });

  const paginationOptions = useMemo(() => {
    return {
      sizePerPage: perPage,
      page,
      totalSize: response?.meta?.paging?.totalCount || 0,
    };
  }, [page, perPage, response]);

  const onChange = useCallback(
    (state: TableChangeState<any>) => {
      const { sizePerPage: perPage, page } = state;
      onPageChange({
        perPage,
        page: page || router.query.page,
      });
    },
    [onPageChange, router.query.page]
  );

  return {
    props: {
      data: response?.[itemKey],
      onChange,
      paginationOptions,
      listLoading: isLoading,
    },
    acts: {
      refetch,
    },
  };
};

export default (props: TablePropsType) => {
  const {
    paginationOptions,
    listLoading = false,
    data,
    columns,
    onChange,
  } = props;

  const [dataCache, setDataCache] = useState();
  const [pageCache, setPageCache] = useState<PaginationOptions>();
  const onTableChange = useCallback(
    (type, state: TableChangeState<any>) => {
      onChange(state);
    },
    [onChange]
  );

  useEffect(() => {
    if (data) setDataCache(data);
  }, [data]);
  useEffect(() => {
    if (paginationOptions.totalSize) setPageCache(paginationOptions);
  }, [paginationOptions]);

  return (
    <>
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          sizePerPageList,
          ...paginationOptions,
          totalSize: paginationOptions.totalSize || pageCache?.totalSize || 0,
        })}
      >
        {({ paginationProps, paginationTableProps }: any) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={data || dataCache || []}
                columns={columns}
                onTableChange={onTableChange}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={data} />
                <NoRecordsFoundMessage entities={data} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};
